import React from "react";
import { gameConfigData } from "../data/GameConfig";

class FooterNav extends React.Component {
    constructor(props) {
      super(props);
      
    } 

    render() {
        // console.log("this.props.footerContent = " + JSON.stringify(this.props.footerContent));
        let copyrightTxt = "© Copyright – Prewire Fitness, Inc. (Patent Pending)";
        let privacyPolicyLbl = "Privacy Policy";
        let urlPrivacyPolicy = "https://rewirefitness.app/privacy-policy/";
        let contestTermsLbl = "Contest Terms";
        let urlContestTerms = "https://rewirefitness.app/contests/mental-toughness-challenge-contest-terms/";
        let mailtoLbl = "mailto:info@rewirefitnessapp.com";
        let mailtoContactUs = "Contact Us *";

        if (this.props.footerContent) {
        copyrightTxt = this.props.footerContent.fields.ftCopyrightText;
        privacyPolicyLbl = this.props.footerContent.fields.ftPrivacyPolicyLbl;
        urlPrivacyPolicy = this.props.footerContent.fields.ftPrivacyPolicyLink;
        contestTermsLbl = this.props.footerContent.fields.ftContestTermsLbl;
        urlContestTerms = this.props.footerContent.fields.ftContestTermsLink;
        mailtoLbl = this.props.footerContent.fields.ftContactUsLbl;
        mailtoContactUs = this.props.footerContent.fields.ftContactUsMailto;
        }

        return (
          <div className="footer-nav-container">
            <p className="header-nav-text">
              {copyrightTxt} |{" "}
              <a
                className="footer-nav-link"
                href={urlPrivacyPolicy}
                target="_blank"
              >
                {privacyPolicyLbl}
              </a>{" "}
              |{" "}
              <a
                className="footer-nav-link"
                href={urlContestTerms}
                target="_blank"
              >
                {contestTermsLbl}
              </a>{" "}
              |{" "}
              <a
                className="footer-nav-link"
                href={mailtoContactUs}
                target="_blank"
              >
                {mailtoLbl}
              </a>
            </p>
          </div>
        );
    };
}


export default FooterNav;
