import React from "react";
import MasterClassLink from "../components/master-class-link.component";
import { gameConfigData } from "../data/GameConfig";
import { offersData } from "../data/OffersData"

import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { CenterFocusStrong } from "@material-ui/icons";


// ========PAGED TABLE
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles_Page_1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    fontSize: `13em`
  },
}));

const useStyles_Page_2 = makeStyles({
  table: {
    // minWidth: 500,
    fontSize: `13em`
  },
});

function TablePaginationActions(props) {
  const classes = useStyles_Page_1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root} style={{ fontSize: `3em`}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function CustomPaginationActionsTable(props) {
  const classes = useStyles_Page_2();
  const [page, setPage] = React.useState(0);
  // SET ROWS PER PAGE HERE.
  // USED HERE and also at lines 191 and 194
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  //const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.leaderBoardData.length - page * rowsPerPage);
  // HIDE any empty rows.
  const emptyRows = 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const aHref = "mailto:friend@friends.com";

  let cssClassTable = " leader-board-table";

  // Transform string "minutes:seconds" to hours:minutes:seconds / CoPilot AI
  function transformTime(time) {
    let timeArray = time.split(":");
    let minutes = parseInt(timeArray[0], 10);
    let seconds = parseInt(timeArray[1], 10);
    let timeInSeconds = minutes * 60 + seconds;
    return formatTime(timeInSeconds);
  }

  // Transform seconds to hours:minutes:seconds / CoPilot AI
  function formatTime(timeInSec) {
    var hours = Math.floor(timeInSec / 3600);
    var minutes = Math.floor((timeInSec - hours * 3600) / 60);
    var seconds = timeInSec - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return hours + ":" + minutes + ":" + seconds;
  }

  return (
    <TableContainer
      component={Paper}
      className="leader-board-container-material-ui"
    >
      <Table className={cssClassTable} aria-label="custom pagination table">
        <TableHead className="leader-board-table-header">
          <TableRow>
            <StyledTableCell
              style={{
                padding: 10,
                textAlign: `center`,
                fontSize: `1em`,
                backgroundColor: `#1C1C1C`,
              }}
              className="leader-board-table-header-cell"
            >
              Rank
            </StyledTableCell>
            <StyledTableCell
              style={{
                padding: 10,
                textAlign: `center`,
                fontSize: `1em`,
                backgroundColor: `#1C1C1C`,
              }}
              align="right"
              className="leader-board-table-header-cell"
            >
              Name
            </StyledTableCell>
            <StyledTableCell
              style={{
                padding: 10,
                textAlign: `center`,
                fontSize: `1em`,
                backgroundColor: `#1C1C1C`,
              }}
              align="right"
              className="leader-board-table-header-cell lbt-wide-cell"
            >
              Reaction Time
            </StyledTableCell>
            <StyledTableCell
              style={{
                padding: 10,
                textAlign: `center`,
                fontSize: `1em`,
                backgroundColor: `#1C1C1C`,
              }}
              align="right"
              className="leader-board-table-header-cell lbt-wide-cell"
            >
              Accuracy
            </StyledTableCell>
            <StyledTableCell
              style={{
                padding: 10,
                textAlign: `center`,
                fontSize: `1em`,
                backgroundColor: `#1C1C1C`,
              }}
              align="right"
              className="leader-board-table-header-cell lbt-wide-cell"
            >
              Total Duration
            </StyledTableCell>
            <StyledTableCell
              style={{
                padding: 10,
                textAlign: `center`,
                fontSize: `1em`,
                backgroundColor: `#1C1C1C`,
              }}
              align="right"
              className="leader-board-table-header-cell"
            >
              Total Score
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody className="leader-board-table-body">
          {(rowsPerPage > 0
            ? props.leaderBoardData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : props.leaderBoardData
          ).map((x, i) => (
            <StyledTableRow
              key={i}
              data={x}
              className={`leader-board-table-row`}
            >
              <TableCell
                style={{ padding: 10, textAlign: `center`, fontSize: `0.8em` }}
                align="left"
                className="leader-board-table-body-cell"
              >
                {x.rank}
              </TableCell>
              <TableCell
                style={{ padding: 10, textAlign: `center`, fontSize: `0.8em` }}
                align="right"
                className="leader-board-table-body-cell"
              >
                {x.name}
              </TableCell>
              <TableCell
                style={{ padding: 10, textAlign: `center`, fontSize: `0.8em` }}
                align="right"
                className="leader-board-table-body-cell lbt-wide-cell"
              >
                {x.reactionTime} Sec
              </TableCell>
              <TableCell
                style={{ padding: 10, textAlign: `center`, fontSize: `0.8em` }}
                align="right"
                className="leader-board-table-body-cell lbt-wide-cell"
              >
                {x.accuracy}%
              </TableCell>
              <TableCell
                style={{ padding: 10, textAlign: `center`, fontSize: `0.8em` }}
                align="right"
                className="leader-board-table-body-cell lbt-wide-cell"
              >
                {transformTime(x.totalDuration)}
              </TableCell>
              <TableCell
                style={{ padding: 10, textAlign: `center`, fontSize: `0.8em` }}
                align="right"
                className="leader-board-table-body-cell"
              >
                {x.totalScore}
              </TableCell>
            </StyledTableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter style={{ fontSize: `3em` }}>
          <TableRow style={{ fontSize: `3em` }}>
            <TablePagination
              rowsPerPageOptions={[
                rowsPerPage,
                50,
                100,
                { label: "All", value: -1 },
              ]}
              colSpan={6}
              count={props.leaderBoardData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

// ========END PAGED TABLE
// +++++++++++++++++++++++++++++++++++++++++++++

class LeaderBoard extends React.Component {
  constructor(props) {
    super(props);
  }

  handleViewAllResults() {
    window.open(gameConfigData.linkResults, '_blank');
  }

  handleViewRandomWinnerSelection() {
    window.open(offersData.offerVideoURL1, '_blank');
  }

  render() {
    let welcomeText = this.props.welcomeTxt;
    let offersMtcDescription = this.props.scienceDescriptionTxt;
    let offersText = offersData.offerText;
    let offersDate = offersData.offerDate;
    let offerChallenge = offersData.offerChallenge;
    let offerWinners = offersData.offerWinners;
    let offersContent = (
      <div className="leader-board-offer-container">
        <span className="leader-board-h3">{offersText}</span>
        <span className="leader-board-h4">{offerChallenge} <b>{offerWinners}</b> {offersDate}</span>
      </div>
    );

    let mtcDescriptionDiv = (
      <div className="leader-board-offer-container">
        <span className="leader-board-h3">{offersMtcDescription}</span>
      </div>
    )

    if (!offersData.isOfferVisible) {
      offersContent = mtcDescriptionDiv;
    }
    
    return (
      <div className="leader-board-container">
        <span className="leader-board-h1 leader-board-top-margin">
          {welcomeText}
        </span>
        <button
          className="btn-take-challange-now"
          onClick={this.props.onClickTakeChallengeNow}
        >
          {this.props.takeTheChallengeBtnTxt}
        </button>
        {/* OFFERS HERE */}
        {offersContent}
        {/* <button
          className="btn-watch-random-selection"
          onClick={this.handleViewRandomWinnerSelection}
        >
          WATCH RANDOM SELECTION OF 4th WINNER
        </button> */}
        <div className="leader-board-title">
          <span className="leader-board-h2 leader-board-title-top-margin">
            {this.props.leaderboardTitleTxt}
          </span>
        </div>
        <CustomPaginationActionsTable leaderBoardData={this.props.leaderBoardData}/>
        <MasterClassLink 
          fpCtatext={this.props.fpCtaTxt} 
          fpBtnDownloadApp={this.props.fpBtnDownloadAppTxt}
          fpBtnDownloadAppLnk={this.props.fpBtnDownloadAppLnk} 
          fpBtnLearnAbout={this.props.fpBtnLearnAboutTxt}
          fpBtnLearnAboutLnk={this.props.fpBtnLearnAboutLnk}/>
      </div>
    );
  }
}



export default LeaderBoard;
