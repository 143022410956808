export const userData = {
    userInfo: {
        "nickname": "andokal",
        "email": "avery100@SpeechGrammarList.com",
        "primaryInterest": "Just Curious",
        "receivePR": 1,
        "IDDB": 0
    },

    userState: {
        "interval": 1,
        "intervalDuration": 0, // Seconds
        "intervalPoints": 0,
        "intervalCorrectAnswers": 0,
        "intervalIncorrectAnswers": 0,
		"intervalAccuracy": 0, // Percent
		"intervalReactionTime": 0, //Seconds
        "currentMentalLevel": 0,
        "previousMentalLevel": 1,
        "rememberGame": 0
    },

    userGameStats: {
        "totalPoints": 0,
        "totalDuration": 0, // Seconds
        "totalCorrectAnswers": 0,
        "averageAccuracy": 0.00,
        "averageReactionTime": 0.00, // Seconds
        "highestMentalLevel": 1,
        "rank": 1,
        "toughnessLevel": 1
    }
};
    