import React from "react";
import { gameConfigData } from "../data/GameConfig";

function ButtonLearnMore(props) {
    // Concatenate css
    return (
        <button 
            className="btn-learn-more" 
            onClick={props.onClick}>
            {props.btnLbl}
        </button>
    );
}

class HeaderNav extends React.Component {
    constructor(props) {
      super(props);
    //   this.state = {};
    } 

    goToLearnMore () {
        window.open(this.props.headerContent.fields.hdBtnFindOutMoreLink, '_blank');
    }
    
    render() {
        const styles = {
          white: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
          black: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
          topBarHeight: 40,
          footerMenuHeight: 50
        };

        let navStyle = {
            display: "flex",
            alignItems: "stretch",
            width: "100%",
            height: styles.footerMenuHeight,
            backgroundColor: "#333",
            color: "#fff",
            position: "fixed",
            top: 0
        };

        return (
            <div className="header-nav-container">
                <div className="header-nav-logo"></div>
                <div className="header-nav-motto">
                    {this.props.headerContent.fields.hdTitle}
                </div>
                <ButtonLearnMore btnLbl={this.props.headerContent.fields.hdBtnFindOutMoreLbl} onClick={() => this.goToLearnMore()}/>
            </div>
        );
    };
}


export default HeaderNav;
