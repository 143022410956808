import React from "react";

class Instructions extends React.Component {
  constructor(props) {
    super(props);
    this.htTitle = props.howToContent.fields.htTitle;
    this.htBtnLearnToPlay = props.howToContent.fields.htBtnLearnToPlay;
    this.htBtnPlay = props.howToContent.fields.htBtnPlay;
    this.htInstructionsTitle = props.howToContent.fields.htInstructionsTitle;
    this.htYtLink = props.howToContent.fields.htYtLink;
    //this.htYtLink = "https://www.youtube.com/embed/L9HYJbe9Y18";
    this.htInstructionsText = props.howToContent.fields.htInstructionsDescTxt;
  }

  componentDidMount() {
    window.scrollTo(0, 0);

  }

  handleLearnHowToPlay() {
    this.props.onClickShowPracticeInstructions();
  }

  handleSkipInstructions() {
    this.props.onClickSkipInstructions();
  }

  render() {
    return (
      <div className="instructions-container">
        <div className="instructions">
          <span className="instructions-h1">{this.htTitle}</span>
          <br />
          <button
            className="btn-instructions"
            onClick={() => this.handleLearnHowToPlay()}
          >
            {this.htBtnLearnToPlay}
            </button>
          <button
            className="btn-skip-instructions"
            onClick={() => this.handleSkipInstructions()}
          >
            {this.htBtnPlay}
          </button>

          <br />
          <div className="instruction-par" >
            <span className="instructions-h2">{this.htInstructionsTitle}</span>
          </div>
          <br />
          <iframe width="88%" height="380" src={this.htYtLink} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowFullScreen="allowFullScreen"></iframe>
          {/* {this.htInstructionsText} */}
          <div className="instruction-par" dangerouslySetInnerHTML= {{__html: this.htInstructionsText}}/>
        </div>
      </div>
    );
  }
}

export default Instructions;
