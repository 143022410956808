export const offersData = {
    isOfferVisible: false,
    offersMtcDescription: "Our Neuro Training is backed by over 10 years of science and has been proven to improve mental resilience and willpower",
    offerText: "Win a Rewire Founder's Subscription Total Value of: $1,981 (4 Winners)",
    //offerDate: "12/21/20",
    offerDate: "",
    offerChallenge: "Congratulations to our Winners:",
    offerWinners: "TwTv SirKissStrats, Bubi, Ronzzu & Wassy!",
    offerVideoURL1: "https://www.youtube.com/watch?v=W1HzY69hfdI&feature=youtu.be",
    offerDurationInDays: "30"
};
    