import React from "react";
import GameOver3iPhones from "../gameover-three-iphones.png";
import MasterClassLink from "../components/master-class-link.component";
import { gameConfigData } from "../data/GameConfig";
import { API, graphqlOperation } from "aws-amplify";

import PropTypes from "prop-types";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

// ========PAGED TABLE
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles_Page_1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    fontSize: `13em`,
  },
}));

const useStyles_Page_2 = makeStyles({
  table: {
    // minWidth: 500,
    fontSize: `13em`,
  },
});

function TablePaginationActions(props) {
  const classes = useStyles_Page_1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root} style={{ fontSize: `3em` }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function CustomPaginationActionsTable(props) {
  const classes = useStyles_Page_2();
  const [page, setPage] = React.useState(0);
  // SET ROWS PER PAGE HERE.
  // USED HERE and also at lines 191 and 194
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  //const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.leaderBoardData.length - page * rowsPerPage);
  // HIDE any empty rows.
  const emptyRows = 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const aHref = "mailto:friend@friends.com";

  let cssClassTable = " leader-board-table";

  // Transform string "minutes:seconds" to hours:minutes:seconds / CoPilot AI
  function transformTime(time) {
    let timeArray = time.split(":");
    let minutes = parseInt(timeArray[0], 10);
    let seconds = parseInt(timeArray[1], 10);
    let timeInSeconds = minutes * 60 + seconds;
    return formatTime(timeInSeconds);
  }

  // Transform seconds to hours:minutes:seconds / CoPilot AI
  function formatTime(timeInSec) {
    var hours = Math.floor(timeInSec / 3600);
    var minutes = Math.floor((timeInSec - hours * 3600) / 60);
    var seconds = timeInSec - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return hours + ":" + minutes + ":" + seconds;
  }

  return (
    <TableContainer
      component={Paper}
      className="leader-board-container-material-ui"
    >
      <Table className={cssClassTable} aria-label="custom pagination table">
        <TableHead className="leader-board-table-header">
          <TableRow>
            <StyledTableCell
              style={{
                padding: 10,
                textAlign: `center`,
                fontSize: `1em`,
                backgroundColor: `#1C1C1C`,
              }}
              className="leader-board-table-header-cell"
            >
              Rank
            </StyledTableCell>
            <StyledTableCell
              style={{
                padding: 10,
                textAlign: `center`,
                fontSize: `1em`,
                backgroundColor: `#1C1C1C`,
              }}
              align="right"
              className="leader-board-table-header-cell"
            >
              Name
            </StyledTableCell>
            <StyledTableCell
              style={{
                padding: 10,
                textAlign: `center`,
                fontSize: `1em`,
                backgroundColor: `#1C1C1C`,
              }}
              align="right"
              className="leader-board-table-header-cell lbt-wide-cell"
            >
              Reaction Time
            </StyledTableCell>
            <StyledTableCell
              style={{
                padding: 10,
                textAlign: `center`,
                fontSize: `1em`,
                backgroundColor: `#1C1C1C`,
              }}
              align="right"
              className="leader-board-table-header-cell lbt-wide-cell"
            >
              Accuracy
            </StyledTableCell>
            <StyledTableCell
              style={{
                padding: 10,
                textAlign: `center`,
                fontSize: `1em`,
                backgroundColor: `#1C1C1C`,
              }}
              align="right"
              className="leader-board-table-header-cell lbt-wide-cell"
            >
              Total Duration
            </StyledTableCell>
            <StyledTableCell
              style={{
                padding: 10,
                textAlign: `center`,
                fontSize: `1em`,
                backgroundColor: `#1C1C1C`,
              }}
              align="right"
              className="leader-board-table-header-cell"
            >
              Total Score
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody className="leader-board-table-body">
          {(rowsPerPage > 0
            ? props.leaderBoardData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : props.leaderBoardData
          ).map((x, i) => (
            <StyledTableRow
              key={i}
              data={x}
              className={`leader-board-table-row`}
            >
              <TableCell
                style={{ padding: 10, textAlign: `center`, fontSize: `0.8em` }}
                align="left"
                className="leader-board-table-body-cell"
              >
                {x.rank}
              </TableCell>
              <TableCell
                style={{ padding: 10, textAlign: `center`, fontSize: `0.8em` }}
                align="right"
                className="leader-board-table-body-cell"
              >
                {x.name}
              </TableCell>
              <TableCell
                style={{ padding: 10, textAlign: `center`, fontSize: `0.8em` }}
                align="right"
                className="leader-board-table-body-cell lbt-wide-cell"
              >
                {x.reactionTime} Sec
              </TableCell>
              <TableCell
                style={{ padding: 10, textAlign: `center`, fontSize: `0.8em` }}
                align="right"
                className="leader-board-table-body-cell lbt-wide-cell"
              >
                {x.accuracy}%
              </TableCell>
              <TableCell
                style={{ padding: 10, textAlign: `center`, fontSize: `0.8em` }}
                align="right"
                className="leader-board-table-body-cell lbt-wide-cell"
              >
                {transformTime(x.totalDuration)}
              </TableCell>
              <TableCell
                style={{ padding: 10, textAlign: `center`, fontSize: `0.8em` }}
                align="right"
                className="leader-board-table-body-cell"
              >
                {x.totalScore}
              </TableCell>
            </StyledTableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter style={{ fontSize: `3em` }}>
          <TableRow style={{ fontSize: `3em` }}>
            <TablePagination
              rowsPerPageOptions={[
                rowsPerPage,
                50,
                100,
                { label: "All", value: -1 },
              ]}
              colSpan={6}
              count={props.leaderBoardData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

// ========END PAGED TABLE

function ButtonTakeChallangeAgain(props) {
  // Concatenate css
  return (
    <button className="btn-take-challange-again" onClick={props.onClick}>
      TAKE CHALLENGE AGAIN
    </button>
  );
}

function ButtonFB(props) {
  // Concatenate css
  return <button className="btn-share-fb" onClick={props.onClick}></button>;
}

function ButtonTwitter(props) {
  // Concatenate css
  return (
    <button className="btn-share-twitter" onClick={props.onClick}></button>
  );
}

function ButtonLinkdin(props) {
  // Concatenate css
  return (
    <button className="btn-share-linkdin" onClick={props.onClick}></button>
  );
}

function ButtonEmail(props) {
  // Concatenate css
  return <button className="btn-share-email" onClick={props.onClick}></button>;
}

function ButtonViewLeaderBoard(props) {
  // Concatenate css
  return (
    <button className="btnViewLeaderBoard" onClick={props.onClick}>
      VIEW LEADERBOARD
    </button>
  );
}

function ButtonViewInstructions(props) {
  // Concatenate css
  return (
    <button className="btn-take-challange-again" onClick={props.onClick}>
      VIEW INSTRUCTIONS
    </button>
  );
}

function StatsCard(props) {
  const cssTxtTop = "card-left-just-txt-top";
  const cssTxt = "card-left-just-txt";
  const styleInline = { lineHeight: "2.5em" };

  return (
    <div className="card-between-intervals">
      <div className="card-between-intervals-h1">Well Done.</div>
      <div className={cssTxtTop}>
        You've just earned{" "}
        <span className="card-between-intervals-data">
          {props.usrData.userState.intervalPoints} points
        </span>
      </div>
      <div className={cssTxt}>
        Total Score:{" "}
        <span className="card-between-intervals-data" style={styleInline}>
          {props.usrData.userGameStats.totalPoints}
        </span>
        <br />
        Total Duration:{" "}
        <span className="card-between-intervals-data" style={styleInline}>
          {props.usrTotalDuration}
        </span>
        <br />
        Correct Answers:{" "}
        <span className="card-between-intervals-data" style={styleInline}>
          {props.usrData.userState.intervalCorrectAnswers}
        </span>
        <br />
        Accuracy:{" "}
        <span className="card-between-intervals-data" style={styleInline}>
          {props.usrData.userState.intervalAccuracy}%
        </span>
        <br />
        Reaction Time:{" "}
        <span className="card-between-intervals-data" style={styleInline}>
          {props.usrIntervalReactionTime} sec
        </span>
        <br />
        Current Mental Level:{" "}
        <span className="card-between-intervals-data" style={styleInline}>
          {props.usrData.userState.previousMentalLevel}
        </span>
        <br />
      </div>
    </div>
  );
}

function LevelUpCard(props) {
  const userData = props.usrData;
  let content;

  if (userData.userState.currentMentalLevel === 1) {
    content = (
      <div className="card-between-intervals">
        <div className="card-between-intervals-h1">Well Done!</div>
        <div className="card-between-intervals-h2">Starting Challenge...</div>
        <div className="card-count-down-txt">{props.secCtdDwn}</div>
        {/* <div>
                    <ButtonTakeChallangeAgain onClick={() => props.onClick(1)}/>
                </div> */}
      </div>
    );
  } else {
    content = (
      <div className="card-between-intervals">
        <div className="card-between-intervals-h1">Leveled Up!</div>
        <div className="card-between-intervals-h2">
          Starting Level {userData.userState.currentMentalLevel}
        </div>
        <div className="card-between-intervals-h3">
          Good
          <br />
          Luck!
        </div>
      </div>
    );
  }

  return content;
}

function LevelDownCard(props) {
  const userData = props.usrData;

  return (
    <div className="card-between-intervals">
      <div className="card-between-intervals-h1">Too Many Misses!</div>
      <div className="card-between-intervals-h2">
        Starting Level {userData.userState.currentMentalLevel}
      </div>
      <div className="card-between-intervals-h3">
        Good
        <br />
        Luck!
      </div>
    </div>
  );
}

function handleViewMasterClass(btnLink) {
  //window.open(gameConfigData.linkMasterClass, "_blank");
  window.open(btnLink, "_blank");
  window.ga_debug = { trace: true };
  window.ga("send", "event", "bttn", "click", "masterclass");
}

function handleViewGameOverLearnMore(btnLink) {
  //window.open(gameConfigData.linkGameOverLearnMore, "_blank");
  window.open(btnLink, "_blank");
  window.ga_debug = { trace: true };
  window.ga("send", "event", "bttn", "click", "masterclass");
}

function GameOverCard(props) {
  const cssCardGameOverScoreClassName = "card-game-over-score";
  const cssTxtTop = "card-center-txt-top";
  const cssTxtTopMinusOne = " text-style-1";
  const cssTxt = "card-left-just-txt";
  const cssTxtCtr = "card-center-txt";
  const toughnessLevelTitle =
    gameConfigData.mentalToughnessRank[
      props.usrData.userState.currentMentalLevel
    ][0];
  const toughnessLevelDescription =
    gameConfigData.mentalToughnessRank[
      props.usrData.userState.currentMentalLevel
    ][1];
  return (
    <div className="card-game-over-top-section">
      <div className="card-game-over-h1">
        {props.gameOvrCrdResPageCont.fields.rpCongratsTitle}
      </div>
      <div className={cssTxtTop}>
        {props.gameOvrCrdResPageCont.fields.rpTotalScoreLbl} <span className={cssCardGameOverScoreClassName}>{props.usrData.userGameStats.totalPoints}</span>
        <br />
        <span className={cssTxtTopMinusOne}>{props.gameOvrCrdResPageCont.fields.rpRankingLbl}</span> <span className={cssCardGameOverScoreClassName + cssTxtTopMinusOne}>#{props.myRank}</span>
        <br />
        <span className={cssTxtTopMinusOne}>{props.gameOvrCrdResPageCont.fields.rpToughnessLevLbl}{" "}</span>
        <span className={cssCardGameOverScoreClassName + cssTxtTopMinusOne}>{toughnessLevelTitle}</span>
      </div>
      <div className="card-game-over-rank-desc">
        {toughnessLevelDescription} You ranked in the top {props.myPercent}% of
        athletes.
      </div>
      <button
        className="btn-master-class leader-board-h2-center btn-background-color-go-red"
        onClick={() => handleViewGameOverLearnMore(props.gameOvrCrdResPageCont.fields.rpBtnLearnMoreLink)}
      >
        {props.gameOvrCrdResPageCont.fields.rpBtnLearnMore}
      </button>
      <div className="leader-board-title  leader-board-h2-center">
        <span className="leader-board-h2 leader-board-title-top-margin">
        {props.gameOvrCrdResPageCont.fields.rpLeaderBoardTitle} 
        </span>
      </div>
      <CustomPaginationActionsTable leaderBoardData={props.leaderBoardData} />
      <div className="leader-board-h2-center card-game-over-line-height">
        <span className={cssTxtTop}>
          {props.gameOvrCrdResPageCont.fields.rpCtatitle}
        </span>
        <br /><br />
        <span className="card-game-over-rank-desc card-game-over-line-height">
          <div dangerouslySetInnerHTML= {{__html: props.gameOvrCrdResPageCont.fields.rpCtasubTitle}}/>
          <br />
          <br />
        </span>
      </div>
      <div>
        <img
          src={"https:" + props.gameOvrCrdResPageCont.fields.rpGameOverBgImage.fields.file.url}
          resizeMode={"cover"}
          style={{ width: "100%", height: "100%", marginBottom: "20px" }}
        />
      </div>
      <div className="leader-board-h2 leader-board-h2-center card-game-over-line-height">
        {props.gameOvrCrdResPageCont.fields.rpCtadescription}
      </div>
      <button
        className="btn-master-class leader-board-h2-center btn-background-color-go-red"
        onClick={() => handleViewMasterClass(props.gameOvrCrdResPageCont.fields.rpBtnCreateAccountLink)}
      >
        {props.gameOvrCrdResPageCont.fields.rpBtnCreateAccount} 
      </button>
    </div>
  );
}

class CardBetweenIntervals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isStartChallenge: true,
      isLevelUp: true,
      isStatsView: true,
      isGameOver: false,
      secCountDown: 5,
      myPercentile: 100,
      myRank: 1,
      myPersonalBest: 0,
    };
    this.levelStatus = this.props.levelStatus;
    this.statsData = this.props.userStatsData;
    this.secCountDn = 5;
    this.allInterReactionTimeSecDec = 0.0;
    this.isAthleteUpdate = false;
  }

  updateAthleteMutation = async (rowID) => {
    // console.log("------ +++++++ updateAthleteMutation >>>>>>>> rowID = " + rowID);
    const apiData = await API.graphql(
      graphqlOperation(`mutation updateAthlete {
        updateLeaderboardData(input: {id: "${rowID}", 
        accuracy: ${this.statsData.userGameStats.averageAccuracy}, 
        highestMentalLevel: ${this.statsData.userGameStats.highestMentalLevel}, 
        reactionTime: ${this.allInterReactionTimeSecDec}, 
        rank: ${this.statsData.userGameStats.rank}, 
        totalDuration: "${this.millisToMinutesAndSeconds(
          this.statsData.userGameStats.totalDuration
        )}", 
        totalScore: ${this.statsData.userGameStats.totalPoints}}) {
          id
          email
          type
          totalScore
          name
          accuracy
          createdAt
          highestMentalLevel
          rank
          reactionTime
          totalDuration
          updatedAt
        }
      }`)
    );

    // Update the local data so we dont have to call API again.
    for (var i = 0; i < this.props.leaderBoardData.length; i++) {
      if (
        this.statsData.userInfo.email === this.props.leaderBoardData[i].email
      ) {
        // Exists - Is is a highscore?
        if (
          this.statsData.userGameStats.totalPoints >
          this.props.leaderBoardData[i].totalScore
        ) {
          // Update this row local leaderboard so we dont have to call API again.
          this.props.leaderBoardData[i] = apiData.data.updateLeaderboardData;
          // sort by DESC score.
          this.props.leaderBoardData
            .sort(function (a, b) {
              return a.totalScore - b.totalScore;
            })
            .reverse();
          break;
        }
        this.setState({
          myPersonalBest: this.props.leaderBoardData[i].totalScore,
        });
      }
    }

    this.updateRank();
    this.updatePercentile();
  };

  addAthleteMutation = async () => {
    // console.log("adding leaderBoard Athlete");
    // console.log(
    //   "this.statsData.userInfo.nickname = " + this.statsData.userInfo.nickname
    // );
    // console.log(
    //   "this.statsData.userGameStats.rank = " + this.statsData.userGameStats.rank
    // );
    // console.log(
    //   "this.statsData.userInfo.email = " + this.statsData.userInfo.email
    // );
    // console.log(
    //   "this.allInterReactionTimeSecDec = " + this.allInterReactionTimeSecDec
    // );
    // console.log(
    //   "this.statsData.userGameStats.averageAccuracy = " +
    //     this.statsData.userGameStats.averageAccuracy
    // );
    // console.log(
    //   "this.millisToMinutesAndSeconds(this.statsData.userGameStats.totalDuration = " +
    //     this.millisToMinutesAndSeconds(
    //       this.statsData.userGameStats.totalDuration
    //     )
    // );
    // console.log(
    //   "this.statsData.userGameStats.totalPoints = " +
    //     this.statsData.userGameStats.totalPoints
    // );

    const apiData = await API.graphql(
      graphqlOperation(`mutation addAthlete {
        createLeaderboardData(input: {name: "${
          this.statsData.userInfo.nickname
        }", rank: ${this.statsData.userGameStats.rank}, email: "${
        this.statsData.userInfo.email
      }", highestMentalLevel: ${
        this.statsData.userGameStats.highestMentalLevel
      }, reactionTime: ${this.allInterReactionTimeSecDec}, accuracy: ${
        this.statsData.userGameStats.averageAccuracy
      }, totalDuration: "${this.millisToMinutesAndSeconds(
        this.statsData.userGameStats.totalDuration
      )}", totalScore: ${
        this.statsData.userGameStats.totalPoints
      }, type: "LeaderboardData"}) {
          id
          email
          type
          totalScore
          name
          accuracy
          createdAt
          highestMentalLevel
          rank
          reactionTime
          totalDuration
          updatedAt
        }
      }`)
    );

    //console.log(JSON.stringify(apiData.data.createLeaderboardData.items));
    // console.log(JSON.stringify(apiData));

    // Add this atlhete to our local leaderboard so we dont have to call API again.
    this.props.leaderBoardData.push(apiData.data.createLeaderboardData);

    this.setState({
      myPersonalBest: apiData.data.createLeaderboardData.totalScore,
    });

    // sort by DESC score.
    this.props.leaderBoardData
      .sort(function (a, b) {
        return a.totalScore - b.totalScore;
      })
      .reverse();

    this.updateRank();
    this.updatePercentile();
  };

  setACookie(plLevel) {
    let date = new Date();
    date.setTime(date.getTime() + 60 * 60 * 24 * 365);
    document.cookie =
      "mtcPlrLevel=" + plLevel + ";expires=" + date.toUTCString();
  }

  // NOT A HIGHSCORE > UPDATE CURRENT RANK (not leaderboard rank)
  updateCurrentRank() {
    // Set a default of last place.
    this.statsData.userGameStats.rank = this.props.leaderBoardData.length;
    for (var i = 0; i < this.props.leaderBoardData.length; i++) {
      if (
        this.statsData.userGameStats.totalPoints >
        this.props.leaderBoardData[i].totalScore
      ) {
        this.statsData.userGameStats.rank = i + 1;
        break;
      }
    }

    this.setState({
      myRank: this.statsData.userGameStats.rank,
    });
  }

  // A HIGHSCORE > UPDATE leaderboard rank.
  updateRank() {
    for (var i = 0; i < this.props.leaderBoardData.length; i++) {
      if (
        this.statsData.userInfo.email === this.props.leaderBoardData[i].email
      ) {
        this.statsData.userGameStats.rank = i + 1;
        break;
      }
    }

    this.setState({
      myRank: this.statsData.userGameStats.rank,
    });
  }

  updatePercentile() {
    // Calculate percentile top x% of athletes.
    let numScoresInMyPercentile = 1;
    let varMyPercentile = 100;
    for (var i = 0; i < this.props.leaderBoardData.length; i++) {
      if (
        this.props.leaderBoardData[i].email != this.statsData.userInfo.email
      ) {
        if (
          this.props.leaderBoardData[i].totalScore >=
          this.statsData.userGameStats.totalPoints
        ) {
          numScoresInMyPercentile++;
        }
      }
    }

    varMyPercentile = Math.round(
      (numScoresInMyPercentile / this.props.leaderBoardData.length) * 100
    );
    this.setState({
      myPercentile: varMyPercentile,
    });
  }

  updateAthleteDBInfo = async () => {
    // Calculate rank. Rank is absolute position in list.
    // Our Leaderbord is Descending on totalScore.

    // console.log("SEE LEADERBOARD DATA B4 CRUD")
    // console.log(JSON.stringify(this.props.leaderBoardData));

    this.setACookie(this.statsData.userState.currentMentalLevel);

    // Start out assuming its a new atlhete.
    if (!this.isAthleteUpdate) {
      // Check if this athlete exists in the leaderboard.
      for (var i = 0; i < this.props.leaderBoardData.length; i++) {
        if (
          this.statsData.userInfo.email === this.props.leaderBoardData[i].email
        ) {
          // Exists - do we have a new Highscore?
          this.isAthleteUpdate = true;
          if (
            this.statsData.userGameStats.totalPoints >
            this.props.leaderBoardData[i].totalScore
          ) {
            // Update!
            this.setState(
              {
                myPercentile: "-",
                myRank: "-",
                myPersonalBest: this.statsData.userGameStats.totalPoints,
              },
              () => {
                this.updateAthleteMutation(this.props.leaderBoardData[i].id);
              }
            );
            break;
          } else {
            this.setState({
              myPersonalBest: this.props.leaderBoardData[i].totalScore,
            });
            // Not a HIGHSCORE.
            // UPDATE CURRENT RANK (not leaderboard rank)
            this.updateCurrentRank();
            this.updatePercentile();
          }
        }
      }
      // Doesnt exist - add row.
      if (!this.isAthleteUpdate) {
        this.addAthleteMutation();
      }
    } else {
      // Only update highscore.
      // Check if this athlete exists in the leaderboard.
      for (var i = 0; i < this.props.leaderBoardData.length; i++) {
        if (
          this.statsData.userInfo.email === this.props.leaderBoardData[i].email
        ) {
          // Exists - do we have a new Highscore?
          this.isAthleteUpdate = true;
          if (
            this.statsData.userGameStats.totalPoints >
            this.props.leaderBoardData[i].totalScore
          ) {
            // Update!
            this.setState(
              {
                myPercentile: "-",
                myRank: "-",
              },
              () => {
                this.updateAthleteMutation(this.props.leaderBoardData[i].id);
              }
            );

            break;
          } else {
            this.updateRank();
            this.updatePercentile();
          }
        }
      }
    }
  };

  componentDidMount() {
    if (this.levelStatus == "levelDown") {
      this.setState({ isLevelUp: false, isStatsView: false });
    } else if (this.levelStatus == "levelUp") {
      if (this.statsData.userState.currentMentalLevel === 1) {
        this.setState({ isLevelUp: true, isStatsView: false }, () => {
          this.startCountDownTimer();
        });
      } else {
        this.setState({ isLevelUp: true, isStatsView: true }, () => {
          this.startStatsViewTimer();
        });
      }
    } else if (this.levelStatus == "gameOver") {
      this.updateAthleteDBInfo();
      this.setState({
        isLevelUp: false,
        isStatsView: false,
        isGameOver: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props !== prevProps) {
      if (this.levelStatus == "gameOver") {
        this.updateAthleteDBInfo();
        // this.setState({
        //   isLevelUp: false,
        //   isStatsView: false,
        //   isGameOver: true,
        // });
      }
    }
  }

  startCountDownTimer() {
    clearInterval(this.countDownInterval);
    this.countDownInterval = setInterval(() => this.tickCountDownTimer(), 1000);
  }

  tickCountDownTimer() {
    // gameConfigData.betweenIntervalsTimer
    // console.log("tickCountDownTimer TICKED! ");
    this.setState({ secCountDown: this.secCountDn }, () => {
      if (this.secCountDn >= 0) {
        this.secCountDn--;
      } else {
        clearInterval(this.countDownInterval);
      }
    });
  }

  startStatsViewTimer() {
    clearInterval(this.interval);
    this.interval = setInterval(
      () => this.tickStatsViewTimer(),
      gameConfigData.betweenIntervalsTimer
    );
  }

  tickStatsViewTimer() {
    // console.log("tickStatsViewTimer TICKED! ");
    clearInterval(this.interval);
    this.setState({ isStatsView: false });
  }

  millisToMinutesAndSeconds(millis) {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return seconds == 60
      ? minutes + 1 + ":00"
      : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  render() {
    const isLevelUp = this.state.isLevelUp;
    const isStatsView = this.state.isStatsView;
    const isGameOver = this.state.isGameOver;
    const usrStatsData = this.statsData;
    let intervalDurationMinSec = this.millisToMinutesAndSeconds(
      this.statsData.userState.intervalDuration
    );
    let totalDurationMinSec = this.millisToMinutesAndSeconds(
      this.statsData.userGameStats.totalDuration
    );
    let intervalReactionTimeSecDec =
      Math.round(
        (this.statsData.userState.intervalReactionTime / 1000 +
          Number.EPSILON) *
          100
      ) / 100;
    let allIntervalsReactionTimeSecDec = "None";
    if (
      !Number.isNaN(
        Math.round(
          (this.statsData.userGameStats.averageReactionTime / 1000 +
            Number.EPSILON) *
            100
        ) / 100
      )
    ) {
      allIntervalsReactionTimeSecDec =
        Math.round(
          (this.statsData.userGameStats.averageReactionTime / 1000 +
            Number.EPSILON) *
            100
        ) / 100;

      this.allInterReactionTimeSecDec = allIntervalsReactionTimeSecDec;
    }

    let content;
    let content_gameover;
    let cardContainerBorderClassName =
      "card-between-intervals-border cbi-border-height";

    if (isLevelUp) {
      if (isStatsView) {
        // Show the stats view.
        content = (
          <StatsCard
            usrData={usrStatsData}
            usrIntervalDuration={intervalDurationMinSec}
            usrTotalDuration={totalDurationMinSec}
            usrIntervalReactionTime={intervalReactionTimeSecDec}
          />
        );
      } else {
        // Show the LevelUp View.
        content = (
          <LevelUpCard
            usrData={usrStatsData}
            secCtdDwn={this.state.secCountDown}
          />
        );
      }
    } else {
      if (isGameOver) {
        // Show the Game Over Stats view.
        cardContainerBorderClassName = "card-between-intervals-no-border";
        content = (
          <GameOverCard
            usrData={usrStatsData}
            usrTotalDuration={totalDurationMinSec}
            usrAllIntervalsAvgReactionTime={allIntervalsReactionTimeSecDec}
            usrTotalScore={usrStatsData.userGameStats.totalCorrectAnswers}
            myPercent={this.state.myPercentile}
            myRank={this.state.myRank}
            personalBest={this.state.myPersonalBest}
            onClick={this.props.onClick}
            onClickShare={this.props.onClickShare}
            leaderBoardData={this.props.leaderBoardData}
            gameOvrCrdResPageCont = {this.props.resultsPgCont}
          />
        );
      } else {
        // Show the level down view.
        content = <LevelDownCard usrData={usrStatsData} />;
      }
    }
    //document.body.style = 'background: white;';
    return (
      <div>
        <div className={cardContainerBorderClassName}>{content}</div>
        
        {!isGameOver && (
          <MasterClassLink fpCtatext={this.props.fpCtaTxt} 
          fpBtnDownloadApp={this.props.fpBtnDownloadAppTxt}
          fpBtnDownloadAppLnk={this.props.fpBtnDownloadAppLnk} 
          fpBtnLearnAbout={this.props.fpBtnLearnAboutTxt}
          fpBtnLearnAboutLnk={this.props.fpBtnLearnAboutLnk}/>
        )}
        
      </div>
    );
  }
}

export default CardBetweenIntervals;
