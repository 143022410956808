/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLeaderboardData = /* GraphQL */ `
  mutation CreateLeaderboardData(
    $input: CreateLeaderboardDataInput!
    $condition: ModelLeaderboardDataConditionInput
  ) {
    createLeaderboardData(input: $input, condition: $condition) {
      id
      name
      rank
      email
      reactionTime
      accuracy
      totalDuration
      totalScore
      highestMentalLevel
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateLeaderboardData = /* GraphQL */ `
  mutation UpdateLeaderboardData(
    $input: UpdateLeaderboardDataInput!
    $condition: ModelLeaderboardDataConditionInput
  ) {
    updateLeaderboardData(input: $input, condition: $condition) {
      id
      name
      rank
      email
      reactionTime
      accuracy
      totalDuration
      totalScore
      highestMentalLevel
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteLeaderboardData = /* GraphQL */ `
  mutation DeleteLeaderboardData(
    $input: DeleteLeaderboardDataInput!
    $condition: ModelLeaderboardDataConditionInput
  ) {
    deleteLeaderboardData(input: $input, condition: $condition) {
      id
      name
      rank
      email
      reactionTime
      accuracy
      totalDuration
      totalScore
      highestMentalLevel
      type
      createdAt
      updatedAt
    }
  }
`;
