import React from "react";
import ReactDOM from "react-dom";
import { API, graphqlOperation } from "aws-amplify";
import "./App.css";
import {Howl} from "howler"
import InstArrowLeft from "./inst-arrow-left.png";
import InstArrowRight from "./inst-arrow-right.png";
import HeaderNav from "./components/header-nav.component";
import FooterNav from "./components/footer.component";
import InfoForm from "./components/info-form.component";
import Instructions from "./components/instructions.component";
import LeaderBoard from "./components/leader-board.component";
import ProgressBar from "./components/progress-bar.component";
import TapOutFooter from "./components/tap-out-footer.component";
import PracticeFooter from "./components/practice-footer.component";
import CardBetweenIntervals from "./components/card-between-intervals.component";
import { gameConfigData } from "./data/GameConfig";
import { mentalLevelData } from "./data/MentalLevelData";
import { userData } from "./data/UserData";
import { deleteLeaderboardData } from "./graphql/mutations";
import { client } from './client';

// AWS DynamoDB Data
const listLeaderBoardAthletesByHighScoreDesc = `query LeaderBoardByHighScore {
  athleteDESCByScore(type: "LeaderboardData", sortDirection: DESC, limit: 1000) {
    items {
      id
      email
      type
      totalScore
      name
      accuracy
      createdAt
      highestMentalLevel
      rank
      reactionTime
      totalDuration
      updatedAt
    }
  }
}`;




// This class is the square area where the 
// ... playWord appears.
class Square extends React.Component {

  constructor(props) {
    super(props);

    this.cssSquareClsName = "square";

    this.playWordTopPos = 40;
    this.playWordLeftPos = 40;

    this.cssPlayWord = {
      display: "inline-block",
      position: "relative",
      top: `${this.playWordTopPos}%`,
      left: `${this.playWordLeftPos}%`
    }
    // We use these refs to measure the dimensions of 
    // ... these divs so we can position them.
    this.refPlayWordDiv = React.createRef();
    this.refSquareDiv = React.createRef();
  }

  centerPlayWordPosition () {
    let squareWidth = Math.round(this.refSquareDiv.current.getBoundingClientRect().width);
    let playWordWidth = Math.round(this.refPlayWordDiv.current.getBoundingClientRect().width);

    this.playWordLeftPos = (squareWidth / 2) - (playWordWidth / 2);

    let squareHeight = Math.round(this.refSquareDiv.current.getBoundingClientRect().height);
    let playWordheight = Math.round(this.refPlayWordDiv.current.getBoundingClientRect().height);

    this.playWordTopPos = (squareHeight / 2) - (playWordheight / 2);

    this.cssPlayWord = {
      display: "inline-block",
      position: "relative",
      top: `${this.playWordTopPos}px`, 
      left: `${this.playWordLeftPos}px`
    }

    // Re-render the word in postion.
    this.setState({isWordPlayMeasured: true});
  }

  generateRandomPosition () {

    // console.log("PLAYWORD = " + this.props.playWord);

    let squareWidth = Math.round(this.refSquareDiv.current.getBoundingClientRect().width);
    let playWordWidth = Math.round(this.refPlayWordDiv.current.getBoundingClientRect().width);
    let playWordWidthPercent =  Math.round((playWordWidth / squareWidth) * 100);
    // TO DO - these values need adjusting based on viewport width.
    let playWordLeftPosMin = playWordWidthPercent / 2;
    let playWordLeftPosMax = 100 - (playWordWidthPercent);

    // Get a random top position percent.
    this.playWordTopPos = Math.round((Math.random() * (9 - 1) + 1) * 10);
    // Get a random left position percent.
    this.playWordLeftPos = Math.round((Math.random() * (playWordLeftPosMax - playWordLeftPosMin) + playWordLeftPosMin));

    this.cssPlayWord = {
      display: "inline-block",
      position: "relative",
      top: `${this.playWordTopPos}%`, 
      left: `${this.playWordLeftPos}%`
    }

    // Re-render the word in postion.
    this.setState({isWordPlayMeasured: true});
  }

  componentDidMount (prevProps) {
    // Set the initial position of the playWord.
    if (this.props != prevProps) {
      this.centerPlayWordPosition();
    }
  }

  componentDidUpdate (prevProps) {

    // We need to do this here because we need the 
    // correct new playWord and color.
    if (mentalLevelData[userData.userState.currentMentalLevel].movingText) {
      if (this.props != prevProps) {
        this.generateRandomPosition();
      }
    } else {
      if (this.props != prevProps) {
        this.centerPlayWordPosition();
      }
    }
  }

  render () {
    let clsName = this.props.cssColor;
    
    if (mentalLevelData[userData.userState.currentMentalLevel].fadedColors) {
      clsName = "faded-" + this.props.cssColor;
    }

    if (this.props.isWrongAnswer) {
      this.cssSquareClsName = "square square-wrong-answer-animation";
    } else  {
      this.cssSquareClsName = "square";
    }

    let content = 
        <div ref={this.refSquareDiv} className={this.cssSquareClsName} key={+new Date()}>
          <div ref={this.refPlayWordDiv} className={clsName} style={this.cssPlayWord}>
            {this.props.playWord}
          </div>
        </div>

    let boardPracticeContainerStyle = {
      position: "relative",
    };

    if (this.props.isPracticeInstructions) {
      content = 
        <div style={boardPracticeContainerStyle}>
          <div className="board-instructions">
            <b>Instructions:</b>
            <br />
            Match the color of the word or the symbol at the top with the correct lable at the bottom.
          </div>
          <div className="board-instructions-color">
            Color
          </div>
          <div className="board-instructions-labels">
            <img src={InstArrowLeft} className="board-instructions-labels-arrows" />
            Labels
            <img src={InstArrowRight} className="board-instructions-labels-arrows" />
          </div>
          <div ref={this.refSquareDiv} className="square">
            <div ref={this.refPlayWordDiv} className={clsName} style={this.cssPlayWord}>
              {this.props.playWord}
            </div>
          </div>
        </div>
    }

    return (
      content
    );
  }
}

const ButtonLeftRight = React.forwardRef((props, ref) => {
  // Concatenate css
  let clsName = "btnLeftRight " + props.cssColor;
  if (mentalLevelData[userData.userState.currentMentalLevel].fadedColors) {
    clsName = "btnLeftRight faded-" + props.cssColor;
  }
  return (
      <button 
          className={clsName} 
          onClick={props.onClick}
          ref={ref}>
          
          {props.wordTxt}
      </button>
  );
});

//---------- BOARD INSTRUCTIONS
// This class shows playing instructions on the BOARD Game area.		
class BoardInstructions extends React.Component {
  constructor (props) {
    super(props);
  }

  renderSquare(playWrd, cssClr) {
      return (
          <Square
              playWord={playWrd}
              cssColor={cssClr}
              isPracticeInstructions = {this.props.isPracticeInstructions}
              onClick={() => this.props.onClick()}
          />
      );
  }

  renderButtonLeft(txtBtnLeft, colorBtnLeft, scoreBtnLeft) {
      // Sending 0 to click handler to 
      // represent the Left Button
      return (
          <ButtonLeftRight 
              wordTxt={txtBtnLeft}
              cssColor={colorBtnLeft}
          />
      );
  }

  renderButtonRight(txtBtnRight, colorBtnRight, scoreBtnRight) {
      // Sending 1 to click handler to 
      // represent the Right Button
      return (
          <ButtonLeftRight
              wordTxt={txtBtnRight}
              cssColor={colorBtnRight}
          />
      );
  }

  render() {
    return (
          <div className="board">
              <div className="board-row">
                  {this.renderSquare("GREEN", "cssRED",)}
              </div>
              <div className="board-row">
                  {this.renderButtonLeft("BLUE", "cssRED", 1)}
                  {this.renderButtonRight("RED", "cssBLUE", 0)}
              </div>
          </div>
      );
  }
}

//---------- BOARD
// This class is the whole Game area.		
class Board extends React.Component {
  constructor (props) {
    super(props);
    this.btnRight = React.createRef();
    this.btnLeft = React.createRef();
    this.isWrngAnsw = false;
  }

  componentDidMount() {
    // We are using React Refs for the L and R buttons,
    // then we use ReacDOM to find the DOM nodes for those refs...
    // ... then we send those as .bind argments to the DOM event listener to 
    // ... click the buttons outside of React from React! Phew...

    const nodeBtnRight = ReactDOM.findDOMNode(this.btnRight.current);
    const nodeBtnLeft = ReactDOM.findDOMNode(this.btnLeft.current);
    
    document.addEventListener('keydown', function(ndL, ndR, e) {
      if (e.code === "KeyA" || e.code === "KeyZ" || e.code === "ArrowLeft") {
        // Click Left button.
        ndL.click();
        // console.log("handleKeyDown BTN LEFT event.key = " + e.code);
      }
      if (e.code === "KeyD" || e.code === "KeyX" || e.code === "ArrowRight") {
        // Click Right button.
        ndR.click();
        // console.log("handleKeyDown BTN RIGHT event.key = " + e.code);
      }
    }.bind(document, nodeBtnLeft, nodeBtnRight));
    
  }

  renderSquare(playWrd, cssClr, isWrongAnswer) {
      return (
          <Square
              playWord={playWrd}
              cssColor={cssClr}
              isWrongAnswer={isWrongAnswer}
              isPracticeInstructions = {this.props.isPracticeInstructions}
              onClick={() => this.props.onClick()}
          />
      );
  }

  renderButtonLeft(txtBtnLeft, colorBtnLeft, scoreBtnLeft) {
      // Sending 0 to click handler to 
      // represent the Left Button
      return (
          <ButtonLeftRight 
              wordTxt={txtBtnLeft}
              cssColor={colorBtnLeft}
              onClick={() => this.clickedAnswerBtn(scoreBtnLeft)}
              ref={this.btnLeft}
          />
      );
  }

  renderButtonRight(txtBtnRight, colorBtnRight, scoreBtnRight) {
      // Sending 1 to click handler to 
      // represent the Right Button
      return (
          <ButtonLeftRight
              wordTxt={txtBtnRight}
              cssColor={colorBtnRight}
              onClick={() => this.clickedAnswerBtn(scoreBtnRight)}
              ref={this.btnRight}
          />
      );
  }

  // Lets check the answer here so we can 
  // ...show the proper animations in the Square
  // ...after we send answer up to App for processing.
  clickedAnswerBtn (btnScr) {
    // Send it up for processing.
    this.props.onClick(btnScr);

    // Check it for animation purposes.
    if (btnScr===1) {
      this.isWrngAnsw = false;
    } else {
      this.isWrngAnsw = true;
    }
  }

  randomIntFromZeroToRange(range) {
      return Math.floor(Math.random() * (range));
  }

  shouldComponentUpdate (nextProps) {
    
    // console.log("BOARD: shouldComponentUpdate > this.props.score = " + this.props.score); 
    // console.log("BOARD: shouldComponentUpdate > nextProps.score = " + nextProps.score);
    if (this.props.score === nextProps.score) {
      return false;
    }

    // Make sure the red flash anim happens when 
    // the user fails to answer a Q in 5 secs.
    if (this.props.score > nextProps.score) {
      this.isWrngAnsw = true;
    } else {
      this.isWrngAnsw = false;
    }

    return true;
  }

  render() {

      //----- Randomize words and colors.

      // 1. Select random playWord color.
      // This index is the winning index. It matches the winning btnColorWord.
      let idxPlayWordColor = this.randomIntFromZeroToRange(this.props.cssColors.length);
      let playWordColor = this.props.cssColors[idxPlayWordColor];

      // 2. Select random playWord.
      let playWord = this.props.playWords[this.randomIntFromZeroToRange(this.props.playWords.length)];

      // --------- Store for use.
      let playValuesObj = {playWordColor, playWord};

      // 3. Select winning btnColorWord.
      let winningBtnColorWord = this.props.buttonColorWords[idxPlayWordColor];
      // In case we need augmented words..
      // Augmented words come in a slightly different structure:
      // btnColorWords : [augWordOne, augWordTwo]
      let augWordsPairArr;
      if (this.props.isAugWords) {
        // Get random pair of augmented words for this win/lose btn pair.
        let augWordColorObj = this.props.buttonColorWords[idxPlayWordColor];
        let augWordArr = Object.entries(augWordColorObj)[0];
        let tmpAugWrdPairIdx = this.randomIntFromZeroToRange(augWordArr[1].length);
        augWordsPairArr = augWordArr[1][tmpAugWrdPairIdx];
        winningBtnColorWord = augWordsPairArr[0];
      }
      
      // 4. Select the winning btnColor - this is random because 
      //....matching btnColorWord with playWordColor wins.
      let winningBtnColor = this.props.cssColors[this.randomIntFromZeroToRange(this.props.cssColors.length)];

      // --------- Store for use. Also store a 1 for Winner - will use for scoring.
      let winningBtnValuesObj = [winningBtnColor, winningBtnColorWord, 1];
      
      // 5. Select losing btnColorWord random - cannot be the playWordColor.
      let tmpRndLosingIdx = idxPlayWordColor;
      while (tmpRndLosingIdx == idxPlayWordColor) {
          tmpRndLosingIdx = this.randomIntFromZeroToRange(this.props.cssColors.length);
      }
      let losingBtnColorWord = this.props.buttonColorWords[tmpRndLosingIdx];
      // In case we need augmented words...
      if (this.props.isAugWords) {
        // Assign the losing word from our already selected pair.
        losingBtnColorWord = augWordsPairArr[1];
      }

      // 6. Select losing btnColor random - cannot be winningBtnColor.
      let losingBtnColor = winningBtnColor;
      while (losingBtnColor == winningBtnColor) {
          losingBtnColor = this.props.cssColors[this.randomIntFromZeroToRange(this.props.cssColors.length)];
      }

      // --------- Store for use. Also store a -1 for Loser - will use for Scoring.
      let losingBtnValuesObj = [losingBtnColor, losingBtnColorWord, -1];

      // Create a dice.
      let btnDice = [losingBtnValuesObj, winningBtnValuesObj];

      // 7. Assign color and word value pairs randomly to btns.
      let tmpBtnIdxPos = this.randomIntFromZeroToRange(2);
      let tmpBtnIdxNeg = tmpBtnIdxPos < 1 ? 1 : 0;

      let leftBtnWord = btnDice[tmpBtnIdxPos][1];
      let leftWordColor = btnDice[tmpBtnIdxPos][0];
      let leftBtnScore = btnDice[tmpBtnIdxPos][2];
      let rightBtnWord = btnDice[tmpBtnIdxNeg][1];
      let rightWordColor = btnDice[tmpBtnIdxNeg][0];
      let rightBtnScore = btnDice[tmpBtnIdxNeg][2];

      // Switch scores for Reverse Inputs setting.
      if (this.props.isReverseIn) {
        leftBtnScore = btnDice[tmpBtnIdxNeg][2];
        rightBtnScore = btnDice[tmpBtnIdxPos][2];
      }

      return (
          <div className="board">
              <div className="board-row">
                  {this.renderSquare(playWord, playWordColor, this.isWrngAnsw)}
              </div>
              <div className="board-row">
                  {this.renderButtonLeft(leftBtnWord, leftWordColor, leftBtnScore)}
                  {this.renderButtonRight(rightBtnWord, rightWordColor, rightBtnScore)}
              </div>
          </div>
      );
  }
}

//----------- TIMER
// This is the timer for the game interval.
// It is displayed in the game info header.
class Timer extends React.Component {

  constructor(props) {
      super(props);
      this.state = { seconds: 0 };
      this.isTimerBetweenIntervals = 0;
  }

  tick() {
      this.setState(state => ({
          seconds: state.seconds + 1
      }));

      userData.userState.intervalDuration = this.state.seconds * 1000;
  }

  resetToZero() {
      this.setState(state => ({
          seconds: 0
      }));
  }

  startTimer() {
    this.interval = setInterval(() => this.tick(), 1000);
  }

  componentDidMount() {
    if (!this.props.isPracticeInstructions)
      this.startTimer();
  }

  componentWillUnmount() {
      clearInterval(this.interval);
  }

  componentDidUpdate() {
    // Game is between intervals, reset the timer.
    if (this.props.isGameBetweenIntervals && !this.isTimerBetweenIntervals) {
      clearInterval(this.interval);
      this.resetToZero();
      this.isTimerBetweenIntervals = 1;
    }
  }

  render() {
      // format seconds into m:ss
      let secs = this.state.seconds;
      let minutes = Math.floor( (secs % (60 * 60)) / 60);
      let formatedSeconds = Math.ceil((secs % (60 * 60)) % 60);
      formatedSeconds = formatedSeconds < 10 ? '0' + formatedSeconds : formatedSeconds;  

      return (
          <div>{minutes + ':' + formatedSeconds}</div>
      );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    return (
      <button 
          className="btn-header"
          onClick={this.props.onClick}
          >
      </button>
    );
  }
      
}

function HeaderNavGame(props) {
  // console.log("HeaderNavGame(props) = " + JSON.stringify(props.hdrContent));
  return (
    <div className="header-nav-game">
      <HeaderNav 
      headerContent={props.hdrContent}/>
      {/* <HeaderNav /> */}
    </div>
  )
}

//---------- GAME APP
// The main logic for the whole game.
// Winning or losing a question is calculated in the
// ... calculateProgress function.
class App extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        appStatus:  "leaderBoard",// "personalInfoForm",  "instructions", "instructionLabels", "practiceRound", "gamePlay", >> "leaderBoard"
        isPracticeInstructions: true, //true
        isPracticeRound: true, //true
        isGameBetweenIntervals: true, //true
        levelStatus: "playing", // >> "playing", "levelUp", "levelDown", "gameOver"
        score: 0,
        currentInterval: 1,
        progressPercent: 0 ,
        isTapOutFooterVisible: false,
        tapOutCounter: gameConfigData.tapoutTimer,
        confulContent: [{"fields":{"ftCopyrightText":"© Copyright – Rewire Fitness, Inc. (Patent Pending)","ftPrivacyPolicyLbl":"Privacy Policy","ftPrivacyPolicyLink":"https://rewirefitness.app/privacy-policy/","ftContestTermsLbl":"Contest Terms","ftContestTermsLink":"https://rewirefitness.app/contests/mental-toughness-challenge-contest-terms/","ftContactUsMailto":"mailto:info@rewirefitnessapp.com","ftContactUsLbl":"Contact Us"}}],
        athletes: [
          {
              "rank": "...",
              "name": "Loading...",
              "email": "a@test.com",
              "reactionTime": "0.00",
              "accuracy": "0",
              "totalDuration": "...",
              "totalScore": "...",
          }]
      };

      this.audioCtx = "";
      this.isWrongAns = false;

      this.refMainTimer = React.createRef();

      this.soundEffects = new Howl({
        src: ["sounds/sounds.webm", "sounds/sounds.mp3"],
        sprite: {
          correct_answer: [0,626.9387755102041],
          incorrect_answer: [2000,1044.8979591836735],
          interval_change_negative: [5000,1123.2653061224491],
          interval_change_positive: [8000,2194.2857142857138]
        }
      })
      
      this.initGameSettings();
      
      this.updateIntervalGameSettings();

      this.isCookieSet = false;

      this.cookiePlayerName = "";

      this.cookiePlayerEmail = "";

      this.cookiePlayerLevel = 0;

      this.welcomeText = "";

      this.takeTheChallengeBtnTxt = "";

      this.fpDescription = "";

      this.fpLeaderboardTitle = "";

      //======== How To Page Content
      this.howToContent = "";

      // Contentful data indexes, default settings, will be overwritten by contentful data.
      this.resultsPageIndex = 2;
      this.howToPlayPageIndex = 4;
      this.frontPageIndex = 3;
      this.footerContentIndex = 1;
      this.headerContentIndex = 0;

      this.bolClearLeaderboardDB = false;

  }

  listLeaderBoardQuery = async () => {
    const apiData = await API.graphql(
      graphqlOperation(listLeaderBoardAthletesByHighScoreDesc)
    );

    let allAthletes = apiData.data.athleteDESCByScore.items;

    //console.log("listing leaderBoard Athletes apiData = " + JSON.stringify(apiData));

    // If bolClearLeaderboardDB is true, clear the leaderboard.
    if (this.bolClearLeaderboardDB) {
      for (var i = 0; i < allAthletes.length; i++) {
        await API.graphql(
          graphqlOperation(deleteLeaderboardData, {
            input: { id: allAthletes[i].id },
          })
        );
      }
      allAthletes = [];
    } else {
      // Set rank.
      for (var i = 0; i < allAthletes.length; i++) {
        allAthletes[i].rank = i + 1;
      }

      this.setState({
        athletes: allAthletes,
      });
    }
  }
    
    

  getCookieAndSetInfo() {
    // DEBUG - Delete cookies.
    //.cookie = "mtcPlrName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; 
    //document.cookie = "mtcPlrEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; 
    //document.cookie = "mtcPlrLevel=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Get cookies and set player info from them.
    if (document.cookie.split("; ").find((row) => row.startsWith("mtc"))) {
      this.isCookieSet = true;
    }

    if (document.cookie.split("; ").find((row) => row.startsWith("mtcPlrName"))) {
      this.cookiePlayerName = document.cookie.split("; ").find((row) => row.startsWith("mtcPlrName")).split("=")[1];
      userData.userInfo.nickname = this.cookiePlayerName;
    }

    if (document.cookie.split("; ").find((row) => row.startsWith("mtcPlrEmail"))) {
      this.cookiePlayerEmail = document.cookie.split("; ").find((row) => row.startsWith("mtcPlrEmail")).split("=")[1];
      userData.userInfo.email = this.cookiePlayerEmail;
    }

    if (document.cookie.split("; ").find((row) => row.startsWith("mtcPlrLevel"))) {
      this.cookiePlayerLevel = document.cookie.split("; ").find((row) => row.startsWith("mtcPlrLevel")).split("=")[1];
      //userData.userState.currentMentalLevel = this.cookiePlayerLevel;
      // DEBUG
      userData.userState.currentMentalLevel = 1; // 1
    }
    
    // console.log("COOKIES = " + document.cookie);
  }

  componentWillMount() {
    this.getCookieAndSetInfo();
    // If we have a cookie set on first load..
    if (this.isCookieSet) {
      this.welcomeText = "Welcome Back " + userData.userInfo.nickname + "!";
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioCtx = new AudioContext();

    // Get the Contentful Data.
    client.getEntries()
    .then ( (response) => {
      // console.log("Contentful Data = " + JSON.stringify(response.items));
      this.setState({
        confulContent: response.items,
      });

      // Identify the data structure. It changes order when you add/edit new content.
      // Loop the following switch statement for each entry in the confulContent array.
      for (var i = 0; i < this.state.confulContent.length; i++) {
        switch (this.state.confulContent[i].sys.contentType.sys.id) {
          case "fpTitle":
            this.frontPageIndex = i;
            break;
          case "headerContent":
            this.headerContentIndex = i;
            break;
          case "footerContent":
            this.footerContentIndex = i;
            break;
          case "thankYouPage":
            this.resultsPageIndex = i;
            break;
          case "howToPage":
            this.howToPlayPageIndex = i;
            break;
          default:
            // code block
            console.log("ERROR: Contentful data structure not identified.");
        }
      }

      // Front page content.
      if (!this.isCookieSet) {
        this.welcomeText =
        this.state.confulContent[this.frontPageIndex].fields.fpTitle;
      }

      this.takeTheChallengeBtnTxt =
        this.state.confulContent[
          this.frontPageIndex
        ].fields.fpBtnTakeTheChallenge;
      this.fpDescription =
        this.state.confulContent[this.frontPageIndex].fields.fpDescription;
      this.fpLeaderboardTitle =
        this.state.confulContent[this.frontPageIndex].fields.fpLeaderboardTitle;
      this.fpCtatext =
        this.state.confulContent[this.frontPageIndex].fields.fpCtatext;
      this.fpBtnDownloadApp =
        this.state.confulContent[this.frontPageIndex].fields.fpBtnDownloadApp;
      this.fpBtnDownloadAppLink =
        this.state.confulContent[
          this.frontPageIndex
        ].fields.fpBtnDownloadAppLink;
      this.fpBtnLearnAbout =
        this.state.confulContent[this.frontPageIndex].fields.fpBtnLearnAbout;
      this.fpBtnLearnAboutLink =
        this.state.confulContent[
          this.frontPageIndex
        ].fields.fpBtnLearnAboutLink;

      this.bolClearLeaderboardDB =
        this.state.confulContent[this.frontPageIndex].fields.fpBolResetLeaderboard;

      
      // console.log("COMP DID MOUNT: this.bolClearLeaderboardDB = " + this.bolClearLeaderboardDB);

      //console.log("this.state.confulContent[frontPageIndex] = " + JSON.stringify(this.state.confulContent[frontPageIndex]));

      this.howToContent = this.state.confulContent[this.howToPlayPageIndex];
      //console.log("this.howToContent = " + JSON.stringify(this.howToContent));

      this.resultsPgContent = this.state.confulContent[this.resultsPageIndex];
      //console.log("Comp did mount = this.state.confulContent[4] = " + JSON.stringify(this.state.confulContent[4]));

      // console.log("Comp did mount = this.state.confulContent[headerContentIndex] = " + JSON.stringify(this.state.confulContent[this.headerContentIndex]));

      // Get Leaderboard Data.
      // We do it here because we will check if
      // Contentful wants us to clear the DB or not.
      // The check happens in the listLeaderBoardQuery() function.
      this.listLeaderBoardQuery();

    })
    .catch(console.error);

    

  }

// ================== Handle Data

  zeroOutUserData() {
    userData.userState.interval = 1;
    userData.userState.intervalDuration = 0;
    userData.userState.intervalPoints = 0;
    userData.userState.intervalCorrectAnswers = 0;
    userData.userState.intervalIncorrectAnswers = 0;
    userData.userState.intervalAccuracy = 0;
    userData.userState.intervalReactionTime = 0;
    userData.userState.currentMentalLevel = 1;
    userData.userState.rememberGame = 0;

    userData.userGameStats.totalPoints = 0;
    userData.userGameStats.totalDuration = 0;
    userData.userGameStats.totalCorrectAnswers = 0;
    userData.userGameStats.averageAccuracy = 0;
    userData.userGameStats.averageReactionTime = 0;
    userData.userGameStats.highestMentalLevel = 0;
    userData.userGameStats.rank = 1;
    //userData.userGameStats.toughnessLevel = 1;
    
  }

  initGameData () {

    if (this.state.isPracticeInstructions) {
      this.setState ({
        score: 0,
        currentInterval: 1,
        isGameBetweenIntervals: false,
        levelStatus: "playing", // "playing", "levelUp", "levelDown", "gameOver"
        progressPercent: 0 ,
        isTapOutFooterVisible: false,
      });
    } else {
      this.setState ({
        score: 0,
        currentInterval: 1
      }, () => {
        this.initGameSettings();
        this.updateIntervalGameSettings();
        this.updateUserStatsData();
  
        this.setState({
          isGameBetweenIntervals: false,
          levelStatus: "playing", // "playing", "levelUp", "levelDown", "gameOver"
          progressPercent: 0 ,
          isTapOutFooterVisible: false,
          tapOutCounter: gameConfigData.tapoutTimer
        }, () => {
          // This timer tracks the duration of
          // ... individual questions. Its length
          // ... comes from MentalLevelData.js config.
          
          this.startQuestionTimer();
          if (!this.state.isPracticeRound) {
            this.startInactiveTimer();
          }
        });
      });  
    }
  }

  initGameSettings () {

    // This is used to calculate
    // ... Accuracy.
    this.questionStartDateStamp = 0;

    // This is used to calculate
    // ... Reaction Time.
    this.allIntervalsReactionTimeArr = [];

    this.intervalReactionTimesArr = [];
    this.intervalReactionTimeAvg = 0;

    this.intervalAccuracyArr = [];
    this.gameAccuracyAvg = 0;
  }

  updateIntervalGameSettings () {
    this.playWords = mentalLevelData[userData.userState.currentMentalLevel].playWords;
    this.cssColors = mentalLevelData[userData.userState.currentMentalLevel].playColors;
    this.buttonColorWords = mentalLevelData[userData.userState.currentMentalLevel].buttonColorWords;
    this.isAugmentedWords = mentalLevelData[userData.userState.currentMentalLevel].augmentedWords;
    this.isReverseInput = mentalLevelData[userData.userState.currentMentalLevel].reversedInputs;
    this.isFadedColors = mentalLevelData[userData.userState.currentMentalLevel].fadedColors;

    this.wrongAnswers = 0;
    this.correctAnswers = 0;
    this.intervalDuration = 0;
    this.progressPercent = 0;
    this.intervalAccuracy = 0;
    this.reactionTime = 0.00;
    this.currentMentalLevel = userData.userState.currentMentalLevel;

    this.currentIntervalCorrectToLevelUp = mentalLevelData[userData.userState.currentMentalLevel].correctToLevelUp;
    this.currentIntervalWrongToLevelDown = mentalLevelData[userData.userState.currentMentalLevel].wrongToLevelDown;

    this.calculateProgress(this.currentIntervalCorrectToLevelUp, this.currentIntervalWrongToLevelDown, 0);
  }

  updateUserStatsData () {
    userData.userState.interval = this.state.currentInterval;
    userData.userState.intervalPoints = this.state.score;
    userData.userState.intervalCorrectAnswers = this.correctAnswers;
    userData.userState.intervalIncorrectAnswers = this.wrongAnswers;
    //userData.userState.intervalDuration > already being updated by timer.
    userData.userState.intervalAccuracy = this.intervalAccuracy;
    //userData.userState.intervalReactionTime = this.intervalReactionTimeAvg; > only for correct answers
    userData.userState.currentMentalLevel = this.updateMentalLevel();

    userData.userGameStats.totalPoints += this.state.score;
    userData.userGameStats.totalDuration += userData.userState.intervalDuration;
    userData.userGameStats.totalCorrectAnswers += this.correctAnswers;
    userData.userGameStats.averageAccuracy = 
      this.calculateTotalAvgAccuracy(userData.userGameStats.averageAccuracy, 
                                this.intervalAccuracy);
    userData.userGameStats.averageReactionTime = 
      this.calculateTotalAvgReactionTime(userData.userGameStats.averageReactionTime, 
                                    this.reactionTime);
    userData.userGameStats.highestMentalLevel = 
      (userData.userState.currentMentalLevel > userData.userGameStats.highestMentalLevel) ? 
      userData.userState.currentMentalLevel : userData.userGameStats.highestMentalLevel;
    //userData.userGameStats.rank = this.calculateRank();
    userData.userGameStats.toughnessLevel = this.calculateToughnessLevel();
  }

  updateMentalLevel () {
    let usrDataCurrMentalLvl = userData.userState.currentMentalLevel;
    userData.userState.previousMentalLevel = userData.userState.currentMentalLevel;
    if (this.state.levelStatus === "levelUp") {
      if (usrDataCurrMentalLvl < 10) {
        // After level 10, restart level 10.
        usrDataCurrMentalLvl++;
      }
    }
    if (this.state.levelStatus === "levelDown") {
      // We never fail down into Practice Level 0.
      if (usrDataCurrMentalLvl > 1) {
         usrDataCurrMentalLvl--;
      }

      // TEST - testing practice level
      //usrDataCurrMentalLvl = 0;
    }

    return (usrDataCurrMentalLvl);
  }

  updateAvgReactionTime () {
    this.intervalReactionTimeAvg = this.calculateAverageOfArray(this.intervalReactionTimesArr);
    userData.userState.intervalReactionTime = this.intervalReactionTimeAvg;

    this.allIntervalsReactionTimeArr.push(this.intervalReactionTimeAvg);
    userData.userGameStats.averageReactionTime = this.calculateAverageOfArray(this.allIntervalsReactionTimeArr);
  }

  updateAvgAccuracyTime () {
    this.intervalAccuracyArr.push(this.intervalAccuracy);
    userData.userGameStats.averageAccuracy =  this.calculateAverageOfArray (this.intervalAccuracyArr);
  }

  calculateAverageOfArray(array) {
    var i = 0, sum = 0, len = array.length;
    while (i < len) {
        sum = sum + array[i++];
    }
    return (Math.round(((sum / len) + Number.EPSILON) * 100) / 100);
  }

  // =============== Handle Input

  handleTapOut () {
    // console.log("handleTapOut");
    var shouldTapOut = window.confirm('Are you sure you want to Tap Out?');
    if (shouldTapOut) {
      this.tapOut();
    }
  }

  tapOut() {
    // console.log("TapOut");
    this.soundEffects.play("interval_change_positive");
    this.isWrongAns = false;
    clearTimeout(this.inactiveTimer);
    clearInterval(this.tapoutInterval);
    this.updateUserStatsData();
    this.updateAvgReactionTime();
    this.updateAvgAccuracyTime();
    this.setState({
      isGameBetweenIntervals: true, 
      levelStatus: "gameOver"
    });
  }

  handleHeaderClick () {
    this.setState({
      isGameBetweenIntervals: true, 
      levelStatus: "gameOver",
      appStatus: "leaderBoard"
    });
    this.listLeaderBoardQuery();
    this.getCookieAndSetInfo();
    if (this.isCookieSet) {
      this.welcomeText = "Play again, " + userData.userInfo.nickname + "?";
    }
  }

    // This handles all clicks to the two answer buttons.
  // It is also triggered by the Question Timer, when it
  // ... times out it clicks a "wrong answer".
  handleClick (btnScore) {
    this.audioCtx.resume();
    if (!this.state.isPracticeRound) {
      this.startInactiveTimer();
    }
    this.updateScore (btnScore);
  }

  restartGame() {
    // Reset level to 1
    this.currentMentalLevel = 1;
    if (this.currentMentalLevel > 0) {
      this.tickTweenIntervalTimer();
      this.setState({
        appStatus: "gamePlay"
      });
      
    }
    this.handleAfterGameClick (1);
  }

  // We are hiding the game during 
  // Campaigns...
  // handleShowInfoForm () {
  //   this.setState({
  //     appStatus: "personalInfoForm"});
  // }

  // Undhide the game.
  handleShowInfoForm () {
    if (this.cookiePlayerName != "") {
      // We have a registered athlete.
      // Show instructions first.
      this.handleShowInstructions();
    } else {
      this.setState({
        appStatus: "personalInfoForm"});
    }
  }

  handleShowInstructions() {
    this.setState({
      appStatus: "instructions"});
  }

  handleShowPracticeInstructions() {
    // console.log("CLICKED > handleShowPracticeInstructions");
    //this.handleAfterGameClick (1);
    this.setState({
        appStatus: "instructionLabels",
        isPracticeInstructions: true,
        isGameBetweenIntervals: true});
  }
  

  handleStartPracticeRound () {
    // console.log("CLICKED > handleStartPracticeRound");
    
    this.setState({
      isPracticeInstructions: false,
      isPracticeRound: true,
      appStatus: "gamePlay",
      isGameBetweenIntervals: false}, () => {
        this.handleAfterGameClick (1);
        userData.userState.currentMentalLevel = 0;
        this.refMainTimer.current.startTimer();
    });
  }

  handleAfterGameShareClick (btnID) {
    if (btnID == 1) {
      // console.log("handleAfterGameShareClick: BTN " + btnID + " clicked! > FB");
      window.open(gameConfigData.linkShareFB, '_blank');
    } else if (btnID == 2) {
      window.open(gameConfigData.linkShareTW, '_blank');
    } else if (btnID == 3) {
      window.open(gameConfigData.linkShareLI, '_blank');
    } else if (btnID == 4) {
      window.open(gameConfigData.linkShareEmail, '_blank');
    }
  }

  handleAfterGameClick (btnID) {
    
    // console.log("handleAfterGameClick: BTN " + btnID + " clicked!");
    if (btnID == 1) {
      // Take Challenge Again.
      // Restart Game.

      // Zero out userData.
      this.zeroOutUserData();

      this.initGameData();

      window.scrollTo(0, 0);
      
    } else if (btnID == 2) {
      // Show LeaderBoard.
      this.handleHeaderClick();
    } else if (btnID == 3) {
      // Show Instructions.
      this.handleShowInstructions();
    }
  }

  updateScore (btnScore) {
      let weightedScore = 0;
      
      if (btnScore > 0) {
        this.isWrongAns = false;
        this.correctAnswers++;
        //correctAnswersLeftToGoUp = this.currentIntervalCorrectToLevelUp - this.correctAnswers;
        weightedScore = btnScore * mentalLevelData[userData.userState.currentMentalLevel].correctAnswerScoreWeighting;

        // Store Question Reaction Time in miliseconds.
        let answerDuration = Date.now() - this.questionStartDateStamp;
        this.intervalReactionTimesArr.push(answerDuration);
      } else {
        this.wrongAnswers++;
        this.isWrongAns = true;
        //wrongAnswersLeftToGoDown = this.currentIntervalWrongToLevelDown - this.wrongAnswers;
        weightedScore = btnScore * mentalLevelData[userData.userState.currentMentalLevel].incorrectAnswerScoreWeighting;
      }

      let correctAnswersLeftToGoUp = this.currentIntervalCorrectToLevelUp - this.correctAnswers;
      let wrongAnswersLeftToGoDown = this.currentIntervalWrongToLevelDown - this.wrongAnswers;
      
      if ((userData.userState.currentMentalLevel == 0)) {
        // We are at currentMentalLevel 0 or 1, nowhere to go but up.
        // currentMentalLevel 0 is the Practice level.
        // currentMentalLevel 1 is the first official level.
        wrongAnswersLeftToGoDown = this.currentIntervalWrongToLevelDown;
      }

      if (this.correctAnswers > 0) {
        this.intervalAccuracy = (this.correctAnswers / (this.correctAnswers + this.wrongAnswers)) * 100;
        this.intervalAccuracy =  (Math.round((this.intervalAccuracy + Number.EPSILON) * 100) / 100);
      }

      this.setState({score: this.state.score + weightedScore});
      
      // This will also calculate winning or losing and reset the 
      // ... questions and intervals.
      this.calculateProgress(correctAnswersLeftToGoUp, wrongAnswersLeftToGoDown, btnScore);
  }

  // A very important function. 
  calculateProgress (correctAnsLeftToGoUp, wrongAnsLeftToGoDown, btnScore) {
      
      if (userData.userState.currentMentalLevel == 0) {
          // Its the Practice Session, nowhere to go but up...
          this.progressPercent = this.correctAnswers / this.currentIntervalCorrectToLevelUp * 100;
      } else {
          // We're in the actual game.
          this.progressPercent = (wrongAnsLeftToGoDown / (wrongAnsLeftToGoDown + correctAnsLeftToGoUp)) * 100;
      }
      
      this.progressPercent = Math.round(this.progressPercent);
      this.setState ({progressPercent: this.progressPercent});

      // ======================= We WON OR FAILED this interval.
      // May want to move this out and trigger it from answers to go.
      if (this.progressPercent <= 0) {
        if (userData.userState.currentMentalLevel >= 1) {
          this.soundEffects.play("interval_change_negative");
          // Failed - levelDown.
          // End current interval.
          clearInterval(this.interval);// Stop Question Timer.
          // Store this interval's ReactionTime average.
          this.updateAvgReactionTime();
          this.updateAvgAccuracyTime();
          /*
          this.intervalReactionTimeAvg = this.calculateAverageReactionTime(this.intervalReactionTimesArr);
          this.allIntervalsReactionTimeArr.push(this.intervalReactionTimeAvg);
          userData.userState.intervalReactionTime = this.intervalReactionTimeAvg;
          */
          // OK, we are setting the state levelStatus FIRST...
          // ... and only then we updateUserStatsData as well as
          // ... updating the state isGameBetweenIntervals, because
          // ... isGameBetweenIntervals kicks off the inbetween-interval UI,
          // ... so we need the updated user stats data for that.
          this.setState({levelStatus: "levelDown"}, () => {
            this.updateUserStatsData(); // this happens 2nd
            this.setState({isGameBetweenIntervals: true}, () => {
              // Start inbetween intervals timer.
              // this happens 3rd
              this.startBetweenIntervalsTimer(gameConfigData.betweenIntervalsTimer);
            }); 
          })
        }
      } else if (this.progressPercent >= 100) {
        // Success - levelUp.
        this.soundEffects.play("interval_change_positive");
        // End current interval.
        clearInterval(this.interval);// Stop Question Timer.

        //If it's a practice round, don't store anything.
        if (this.state.isPracticeRound) {
          this.setState({levelStatus: "levelUp"}, () => {
            userData.userState.currentMentalLevel = 1; // this happens 2nd
            this.setState({
              isGameBetweenIntervals: true,
              currentInterval: 1
            }, () => {
              // Start inbetween intervals timer.
              // this happens 3rd
              this.startBetweenIntervalsTimer(gameConfigData.betweenIntervalsTimer);
              }); 
            }
          )
        } else {
          // Store this interval's ReactionTime average.
          this.updateAvgReactionTime();
          this.updateAvgAccuracyTime();
          /*
          this.intervalReactionTimeAvg = this.calculateAverageReactionTime(this.intervalReactionTimesArr);
          this.allIntervalsReactionTimeArr.push(this.intervalReactionTimeAvg);
          userData.userState.intervalReactionTime = this.intervalReactionTimeAvg;
          */
          
          // OK, we are setting the state levelStatus FIRST...
          // ... and only then we updateUserStatsData as well as
          // ... undating the state isGameBetweenIntervals, because
          // ... isGameBetweenIntervals kicks off the inbetween-interval UI,
          // ... so we need updated user stats data for that.
          this.setState({levelStatus: "levelUp"}, () => {
              this.updateUserStatsData(); // this happens 2nd
              this.setState({isGameBetweenIntervals: true}, () => {
                // Start inbetween intervals timer.
                // Up is twice as long becasue we have 2 cards: stats and Go!
                // this happens 3rd
                this.startBetweenIntervalsTimer(gameConfigData.betweenIntervalsTimer * 2);
              }); 
            }
          )
        }
      } else {
        this.startQuestionTimer();
        if (btnScore===1) {
          this.soundEffects.play("correct_answer");
        } else if (btnScore===-1) {
          this.soundEffects.play("incorrect_answer");
        }
      }
  }

  calculateTotalAvgAccuracy (usrDataAvgAccuracy, currentIntervalAccuracy) {
    
    usrDataAvgAccuracy = 
      (usrDataAvgAccuracy + currentIntervalAccuracy) / userData.userState.interval;
    return (Math.round((usrDataAvgAccuracy + Number.EPSILON) * 100) / 100);
  }

  calculateTotalAvgReactionTime (usrDataAvgReactionTime, currentIntervalReactionTime) {
    // TO DO
    usrDataAvgReactionTime = 
    (usrDataAvgReactionTime + currentIntervalReactionTime) / userData.userState.interval;
    return (Math.round((usrDataAvgReactionTime + Number.EPSILON) * 100) / 100);
  }

  calculateRank () {
    // TO DO
    return (7);
  }

  calculateToughnessLevel () {
    // TO DO
    return (userData.userGameStats.toughnessLevel);
  }

  // ================ Timers

  startBetweenIntervalsTimer (timeAmount) {
    clearInterval(this.interval);
    this.interval = setInterval(() => this.tickTweenIntervalTimer(), timeAmount);
  }

  tickTweenIntervalTimer () {
    clearInterval(this.interval);
    
    if (this.state.isGameBetweenIntervals) {
      // Coming from Level Up Or Down: Restart the game.
      // 1. Update Interval Game Settings.
      userData.userState.interval++;
      this.setState(
        {score: 0, 
          currentInterval: userData.userState.interval,
          isPracticeRound: false,
          isPracticeInstructions: false
        }, () => {
          this.updateIntervalGameSettings();
          // 2. Show the game then start Question Timer.
          this.setState( 
            {isGameBetweenIntervals: false, 
              levelStatus: "playing"}, () => {
              this.startQuestionTimer();
              this.startInactiveTimer();
          })
      })
    } 
  }

  startQuestionTimer () {
    clearInterval(this.interval);
    this.interval = setInterval(() => this.tickQuestionTimer(), gameConfigData.questionTimer);
    this.questionStartDateStamp = Date.now();
  }

  tickQuestionTimer () {
    if (!this.state.isGameBetweenIntervals) {
      this.updateScore(-1);
    }
  }

  startInactiveTimer () {
    clearTimeout(this.inactiveTimer);
    clearInterval(this.tapoutInterval);
    // Hide Tapout Warning.
    this.tapOutCounter = gameConfigData.tapoutTimer;
    this.setState({
      isTapOutFooterVisible: false,
      tapOutCounter: this.tapOutCounter});
    this.inactiveTimer = setTimeout(() => this.activateTapOut(), gameConfigData.inactiveTimer);
  }

  activateTapOut () {
    clearTimeout(this.inactiveTimer);
    clearInterval(this.tapoutInterval);
    this.tapoutInterval = setInterval(() => this.tickTapOutTimer(), 1000);
    // Show Tapout Warning.
    this.setState({isTapOutFooterVisible: true});
  }

  tickTapOutTimer () {
    this.tapOutCounter = this.state.tapOutCounter;
    this.tapOutCounter -=  1000;
    // console.log("this.tapOutCounter = " + this.tapOutCounter);
    if (this.tapOutCounter == 0) {
      clearTimeout(this.inactiveTimer);
      clearInterval(this.tapoutInterval);
      // End the game.
      this.soundEffects.play("interval_change_positive");
      this.updateUserStatsData();
      this.updateAvgReactionTime();
      // Show End Stats Card.
      this.setState({
        isGameBetweenIntervals: true, 
        levelStatus: "gameOver"
      });
    } else {
      this.setState({
        tapOutCounter: this.tapOutCounter
      });
    }

    // console.log("this.state.tapOutCounter = " + this.state.tapOutCounter);
  }

  render () {
      const isGameBetweenIntervals = this.state.isGameBetweenIntervals;
      const isPracticeInstructions = this.state.isPracticeInstructions;
      const isPracticeRound = this.state.isPracticeRound;

      let debugStyle = {display: "none"};

      if (gameConfigData.isDebug) {
        debugStyle = {};
      }
      // console.log(" > > this.state.confulContent[this.headerContentIndex] = " + JSON.stringify(this.state.confulContent[this.headerContentIndex]));
      const navHeaderGame = <HeaderNavGame hdrContent={this.state.
        confulContent[this.
        headerContentIndex]}/>
      const navHeaderNonGame = <HeaderNav headerContent={this.state.
        confulContent[this.
        headerContentIndex]}/>
      const navFooter = 
            <div>
              <FooterNav 
              footerContent={this.state.confulContent[this.footerContentIndex]}/>
            </div>
            

      const header = 
        <div className="game-header">
          <Header onClick={() => this.handleHeaderClick()} />
        </div>

      let gameContainer;
      let content;
      let contentGame;
      let contentBoard;
      let contentFooter;

      if (isPracticeInstructions) {
        contentBoard = 
          <BoardInstructions
          score={this.state.score}
          playWords={this.playWords}
          cssColors={this.cssColors}
          buttonColorWords={this.buttonColorWords}
          isPracticeInstructions={isPracticeInstructions}
          isReverseIn={this.isReverseInput}
          onClick={i => this.handleClick(i)}
          />

          contentFooter = 
          <PracticeFooter isTapOutWarningVisible={this.state.isTapOutFooterVisible} tapoutTime={this.state.tapOutCounter/1000} onClick={() => this.handleStartPracticeRound()}/>
          
      } else {
        contentBoard = 
          <Board
          score={this.state.score}
          playWords={this.playWords}
          cssColors={this.cssColors}
          buttonColorWords={this.buttonColorWords}
          isPracticeInstructions={isPracticeInstructions}
          isAugWords={this.isAugmentedWords}
          isReverseIn={this.isReverseInput}
          isFaded={this.isFadedColors}
          onClick={i => this.handleClick(i)}
          />
          contentFooter = 
            <TapOutFooter 
            isAugWords={this.isAugmentedWords}
            isReverseIn={this.isReverseInput}
            isFaded={this.isFadedColors}
            isPracticeRound={isPracticeRound}
            isTapOutWarningVisible={this.state.isTapOutFooterVisible} 
            tapoutTime={this.state.tapOutCounter/1000} 
            onClick={() => this.handleTapOut()}/>
      }

      if (this.isWrongAns) {
        this.cssScoreClsName = "game-info-data score-wrong-answer-animation";
      } else  {
        this.cssScoreClsName = "game-info-data";
      }

      contentGame = 
        <div className="game">
            <div className="game-info" style={debugStyle}>
                <div className="game-info-col">
                    <div className="game-info-head">INTERVAL</div>
                    <div className="game-info-data">{userData.userState.interval}</div>
                </div>
                <div className="game-info-col">
                    <div className="game-info-head">PROGRESS</div>
                    <div className="game-info-data">{this.progressPercent}%</div>
                </div>
                <div className="game-info-col">
                    <div className="game-info-head">MENTAL LEVEL</div>
                    <div className="game-info-data">{userData.userState.currentMentalLevel}</div>
                </div>
            </div>
            <div className="game-info-top-padder"></div>
            <div className="game-info">
                <div className="game-info-col">
                    <div className="game-info-head">ACCURACY</div>
                    <div className="game-info-data">{this.intervalAccuracy}%</div>
                </div>
                <div className="game-info-col gic-center">
                    <div className="game-info-head">INTERVAL POINTS</div>
                    <div className={this.cssScoreClsName} key={+new Date()}>
                        {this.state.score}
                    </div>
                </div>
                <div className="game-info-col">
                    <div className="game-info-head">DURATION</div>
                    <div className="game-info-data">
                      <Timer 
                      isGameBetweenIntervals={this.state.isGameBetweenIntervals}
                      isPracticeInstructions={this.state.isPracticeInstructions}
                      ref={this.refMainTimer}
                      />
                    </div>
                </div>
            </div>
            <div className="game-info-bottom-padder"><ProgressBar bgcolor={`#ff0000`} completed={this.progressPercent} /></div>
            <div className="game-board">
              {contentBoard}
            </div>
            {contentFooter}
        </div>

      if (!isGameBetweenIntervals) {
        content = contentGame;

        gameContainer = 
          [navHeaderGame, 
          <div className="game-container">
            {header}
            {content}
          </div>]
      } else {
        if (this.state.appStatus==="leaderBoard") {
          // TO DO Set Welcome text here, send as prop.
          //
          gameContainer = [
            navHeaderNonGame,
            <LeaderBoard 
            onClickTakeChallengeNow={() => this.handleShowInfoForm()} 
            ckPlayerName={this.cookiePlayerName}
            ckPlayerEmail={this.cookiePlayerEmail}
            usrData={this.usrData}
            leaderBoardData={this.state.athletes}
            welcomeTxt={this.welcomeText}
            takeTheChallengeBtnTxt={this.takeTheChallengeBtnTxt}
            scienceDescriptionTxt={this.fpDescription}
            leaderboardTitleTxt={this.fpLeaderboardTitle}
            fpCtaTxt={this.fpCtatext}
            fpBtnDownloadAppTxt={this.fpBtnDownloadApp}
            fpBtnDownloadAppLnk={this.fpBtnDownloadAppLink}
            fpBtnLearnAboutTxt={this.fpBtnLearnAbout}
            fpBtnLearnAboutLnk={this.fpBtnLearnAboutLink}
            />
          ]
             
        } else if (this.state.appStatus==="personalInfoForm") {
          gameContainer = [
            navHeaderNonGame,
            <div className="game-container">
              <InfoForm 
                onClickTakeChallengeNow={() => this.handleShowInstructions()}
                userInfoData={userData.userInfo}
                leaderBoardData={this.state.athletes}
              />
            </div>]
        } else if (this.state.appStatus==="instructions") {
          gameContainer = [
            navHeaderNonGame,
            <div className="game-container">
              <Instructions 
                onClickShowPracticeInstructions={() => this.handleShowPracticeInstructions()}
                onClickSkipInstructions={() => this.restartGame()} 
                howToContent={this.howToContent}
              />  
            </div>]
        } else if (this.state.appStatus==="instructionLabels") {
            content = contentGame;

            gameContainer = [
              navHeaderGame,
              <div className="game-container">
                {header}
                {content}
              </div>]
        } else {
          content = (
            <CardBetweenIntervals
              levelStatus={this.state.levelStatus}
              userStatsData={userData}
              onClick={(i) => this.handleAfterGameClick(i)}
              onClickShare={(i) => this.handleAfterGameShareClick(i)}
              leaderBoardData={this.state.athletes}
              resultsPgCont={this.resultsPgContent}
              fpCtaTxt={this.fpCtatext}
              fpBtnDownloadAppTxt={this.fpBtnDownloadApp}
              fpBtnDownloadAppLnk={this.fpBtnDownloadAppLink}
              fpBtnLearnAboutTxt={this.fpBtnLearnAbout}
              fpBtnLearnAboutLnk={this.fpBtnLearnAboutLink}
            />
          );
          gameContainer = [
            navHeaderGame,
            <div className="game-container">
              {header}
              {content}
            </div>,
          ];
        }
      }
      
      return [gameContainer,navFooter];
  }
}



// ========================================


export default App;
