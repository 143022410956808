import React from "react";
import { gameConfigData } from "../data/GameConfig";

class MasterClassLink extends React.Component {
  constructor(props) {
    super(props);
  }

  handleViewMasterClass () {
    // window.open(gameConfigData.linkMasterClass, '_blank');
    window.open(this.props.fpBtnDownloadAppLnk, '_blank');
    window.ga_debug = {trace: true};
    window.ga("send", "event", "bttn", "click", "masterclass");
  }

  handleLearnAboutTheScience () {
    //window.open(gameConfigData.linkLearnAboutTheScience, '_blank');
    window.open(this.props.fpBtnLearnAboutLnk, '_blank');
    window.ga_debug = {trace: true};
    window.ga("send", "event", "bttn", "click", "science");
  }

  render() {
    
    return (
      <div className="master-class-link-container">
        <span className="leader-board-h2">
          {this.props.fpCtatext}
        </span>
        <button 
            className="btn-master-class" 
            onClick={() => this.handleViewMasterClass()}>
            {this.props.fpBtnDownloadApp}
        </button>

        <button 
            className="btn-learn-science" 
            onClick={() => this.handleLearnAboutTheScience()}>
            {this.props.fpBtnLearnAbout}
        </button>
      </div>
    );
  }
}

export default MasterClassLink;
