export const mentalLevelData = {
    0: {
        "mentalLevel": 0, // Practice Session
        "correctToLevelUp": 10, //10
        "wrongToLevelDown": 0, 
        "durationScoreWeighting": 2, // milliseconds per point, duration/durationScoreWeighting always round down
        "correctAnswerScoreWeighting": 1,
        "incorrectAnswerScoreWeighting": 1,
        "pointsPerSecond": 0.5,
        "pointsPerCorrectAnswer": 1,
        "pointsPerIncorrectAnswer": -1,
        "playColors": ["cssRED","cssBLUE"],
        "playWords": ["RED","BLUE"],
        "buttonColorWords": ["RED","BLUE"],
        "movingText": false,
        "fadedColors": false,
        "augmentedWords": false,
        "reversedInputs": false
    },
    1: {
        "mentalLevel": 1,
        "correctToLevelUp": 120, // 120
        "wrongToLevelDown": 60, // 60
        "durationScoreWeighting": 2,
        "correctAnswerScoreWeighting": 1,
        "incorrectAnswerScoreWeighting": 1,
        "pointsPerSecond": 0.5,
        "pointsPerCorrectAnswer": 1,
        "pointsPerIncorrectAnswer": -1,
        "playColors": ["cssRED","cssBLUE"],
        "playWords": ["RED","BLUE"],
        "buttonColorWords": ["RED","BLUE"],
        "movingText": false,
        "fadedColors": false,
        "augmentedWords": false,
        "reversedInputs": false,
        "toughnessLevel": "Newbie",
        "toughnessDescription": "You are not on top of your mental game and are in need of some serious training."
    },
    2: {
        "mentalLevel": 2,
        "correctToLevelUp": 114, //114
        "wrongToLevelDown": 57,  // 57
        "durationScoreWeighting": 3,
        "correctAnswerScoreWeighting": 2,
        "incorrectAnswerScoreWeighting": 2,
        "pointsPerSecond": 1,
        "pointsPerCorrectAnswer": 2,
        "pointsPerIncorrectAnswer": -2,
        "playColors": ["cssRED","cssBLUE","cssGREEN"],
        "playWords": ["RED","BLUE","GREEN"],
        "buttonColorWords": ["RED","BLUE","GREEN"],
        "movingText": false,
        "fadedColors": false,
        "augmentedWords": false,
        "reversedInputs": false,
        "toughnessLevel": "Rookie",
        "toughnessDescription": "You are not on top of your mental game and are in need of some training."
    },
    3: {
        "mentalLevel": 3,
        "correctToLevelUp": 108, // 108
        "wrongToLevelDown": 54, // 54
        "durationScoreWeighting": 4,
        "correctAnswerScoreWeighting": 3,
        "incorrectAnswerScoreWeighting": 3,
        "pointsPerSecond": 1.5,
        "pointsPerCorrectAnswer": 3,
        "pointsPerIncorrectAnswer": -3,
        "playColors": ["cssRED","cssBLUE","cssGREEN"],
        "playWords": ["RED","BLUE","GREEN"],
        "buttonColorWords": ["RED","BLUE","GREEN"],
        "movingText": true,
        "fadedColors": false,
        "augmentedWords": false,
        "reversedInputs": false,
        "toughnessLevel": "Skilled",
        "toughnessDescription": "Your mental game is coming together but there is a lot of room for improvement."
    },
    4: {
        "mentalLevel": 4,
        "correctToLevelUp": 102, // 102
        "wrongToLevelDown": 51, // 51
        "durationScoreWeighting": 2,
        "correctAnswerScoreWeighting": 4,
        "incorrectAnswerScoreWeighting": 4,
        "pointsPerSecond": 2,
        "pointsPerCorrectAnswer": 4,
        "pointsPerIncorrectAnswer": -4,
        "playColors": ["cssRED","cssBLUE","cssGREEN","cssPURPLE"],
        "playWords": ["RED","BLUE","GREEN","PURPLE"],
        "buttonColorWords": ["RED","BLUE","GREEN","PURPLE"],
        "movingText": true,
        "fadedColors": false,
        "augmentedWords": false,
        "reversedInputs": false,
        "toughnessLevel": "Talented",
        "toughnessDescription": "You have a decent ability to maintain focus whilst having good mental endurance."
    },
    5: {
        "mentalLevel": 5,
        "correctToLevelUp": 96,
        "wrongToLevelDown": 48,
        "durationScoreWeighting": 2,
        "correctAnswerScoreWeighting": 5,
        "incorrectAnswerScoreWeighting": 5,
        "pointsPerSecond": 2.5,
        "pointsPerCorrectAnswer": 5,
        "pointsPerIncorrectAnswer": -5,
        "playColors": ["cssRED","cssBLUE","cssGREEN","cssPURPLE"],
        "playWords": ["####","$$$$","@@@","#$@"],
        "buttonColorWords": ["RED","BLUE","GREEN","PURPLE"],
        "movingText": true,
        "fadedColors": false,
        "augmentedWords": false,
        "reversedInputs": false,
        "toughnessLevel": "Advanced",
        "toughnessDescription": "You have a good ability to maintain focus whilst having strong mental endurance."
    },
    6: {
        "mentalLevel": 6,
        "correctToLevelUp": 90,
        "wrongToLevelDown": 45,
        "durationScoreWeighting": 2,
        "correctAnswerScoreWeighting": 6,
        "incorrectAnswerScoreWeighting": 6,
        "pointsPerSecond": 3,
        "pointsPerCorrectAnswer": 6,
        "pointsPerIncorrectAnswer": -6,
        "playColors": ["cssRED","cssBLUE","cssGREEN","cssPURPLE","cssBROWN"],
        "playWords": ["RED","BLUE","GREEN","PURPLE", "BROWN","####","$$$$","@@@","#$@"],
        "buttonColorWords": ["RED","BLUE","GREEN","PURPLE", "BROWN"],
        "movingText": true,
        "fadedColors": false,
        "augmentedWords": false,
        "reversedInputs": false,
        "toughnessLevel": "Expert",
        "toughnessDescription": "You have a very good ability to maintain focus whilst having impressive mental endurance."
    },
    7: {
        "mentalLevel": 7,
        "correctToLevelUp": 84,
        "wrongToLevelDown": 42,
        "durationScoreWeighting": 2,
        "correctAnswerScoreWeighting": 7,
        "incorrectAnswerScoreWeighting": 7,
        "pointsPerSecond": 3.5,
        "pointsPerCorrectAnswer": 7,
        "pointsPerIncorrectAnswer": -7,
        "playColors": ["cssRED","cssBLUE","cssGREEN","cssPURPLE","cssBROWN","cssPINK"],
        "playWords": ["RED","BLUE","GREEN","PURPLE", "BROWN","PINK","####","$$$$","@@@","#$@"],
        "buttonColorWords": ["RED","BLUE","GREEN","PURPLE", "BROWN","PINK"],
        "movingText": true,
        "fadedColors": false,
        "augmentedWords": false,
        "reversedInputs": false,
        "toughnessLevel": "Champion",
        "toughnessDescription": "You have a great ability to maintain focus whilst having fantastic mental endurance."
    },
    8: {
        "mentalLevel": 8,
        "correctToLevelUp": 78,
        "wrongToLevelDown": 39,
        "durationScoreWeighting": 2,
        "correctAnswerScoreWeighting": 8,
        "incorrectAnswerScoreWeighting": 8,
        "pointsPerSecond": 4,
        "pointsPerCorrectAnswer": 8,
        "pointsPerIncorrectAnswer": -8,
        "playColors": ["cssRED","cssBLUE","cssGREEN","cssPURPLE","cssBROWN","cssPINK"],
        "playWords": ["RED","BLUE","GREEN","PURPLE", "BROWN","PINK","####","$$$$","@@@","#$@"],
        "buttonColorWords": ["RED","BLUE","GREEN","PURPLE", "BROWN","PINK"],
        "movingText": true,
        "fadedColors": true,
        "augmentedWords": false,
        "reversedInputs": false,
        "toughnessLevel": "Semi-Pro",
        "toughnessDescription": "You have an excellent ability to maintain focus whilst having exceptional mental endurance."
    },
    9: {
        "mentalLevel": 9,
        "correctToLevelUp": 72,
        "wrongToLevelDown": 36,
        "durationScoreWeighting": 2,
        "correctAnswerScoreWeighting": 9,
        "incorrectAnswerScoreWeighting": 9,
        "pointsPerSecond": 4.5,
        "pointsPerCorrectAnswer": 9,
        "pointsPerIncorrectAnswer": -9,
        "playColors": ["cssRED","cssBLUE","cssGREEN","cssPURPLE"],
        "playWords": ["RED","BLUE","GREEN","PURPLE","####","$$$$","@@@","#$@"],
        "buttonColorWords": [
            {"RED": [
                ["GREDN","GREEN"],
                ["REDE","BLUE"],
                ["PUREDE","PURPLE"],
            ]},
            {"BLUE": [
                ["BLUE","REDE"],
                ["GBLUE","GREEN"],
                ["PUBLUE","PURPLE"],
            ]},
            {"GREEN": [
                ["GREEN","GREDN"],
                ["GREEN","GBLUE"],
                ["GREENE","PURPLE"],
            ]},
            {"PURPLE": [
                ["PURPLE","PUREDE"],
                ["PURPLE","GREENE"],
                ["PURPLE","PUBLUE"],
            ]}
        ],
        "movingText": true,
        "fadedColors": false,
        "augmentedWords": true,
        "reversedInputs": false,
        "toughnessLevel": "Pro",
        "toughnessDescription": "You’re on top of your mental game with an awesome ability to maintain focus whilst having outstanding mental endurance."
    },
    10: {
        "mentalLevel": 10,
        "correctToLevelUp": 66, //66
        "wrongToLevelDown": 33, //33
        "durationScoreWeighting": 2,
        "correctAnswerScoreWeighting": 10,
        "incorrectAnswerScoreWeighting": 10,
        "pointsPerSecond": 5,
        "pointsPerCorrectAnswer": 10,
        "pointsPerIncorrectAnswer": -10,
        "playColors": ["cssRED","cssBLUE","cssGREEN","cssPURPLE","cssBROWN","cssPINK"],
        "playWords": ["RED","BLUE","GREEN","PURPLE", "BROWN","PINK","####","$$$$","@@@","#$@"],
        "buttonColorWords": ["RED","BLUE","GREEN","PURPLE", "BROWN","PINK"],
        "movingText": true,
        "fadedColors": false,
        "augmentedWords": false,
        "reversedInputs": true,
        "toughnessLevel": "Elite",
        "toughnessDescription": "You’re on top of your mental game with a unique ability to maintain focus whilst having phenomenal mental endurance."
    }
    
};
    