import React from "react";
import logo from "./Homescreen Logo.png";
import { API, graphqlOperation } from "aws-amplify";

class InfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: "",
      email: "",
      receivePlan: false,
      interest: "N/A",
      validNickname: false,
      validEmail: false,
      initialSubmit: false,
      submitClicked: false,
      formSubmitted: false,
      formError: false,

    };

    this.refEmailInput = React.createRef();
    this.refNickNameInput = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formMtc = React.createRef();
    this.userInfo = this.props.userInfoData;

    this.isNicknameTaken = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.refNickNameInput.current.focus();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (this.state.initialSubmit) {
          this.validateForm();
        }
      }
    );
  }

  updateAthleteNameMutation = async (rowID) => {
    // console.log("------ +++++++ updateAthleteMutation >>>>>>>> rowID = " + rowID);
    const apiData = await API.graphql(
      graphqlOperation(`mutation updateAthlete {
        updateLeaderboardData(input: {id: "${rowID}", 
        name: "${this.userInfo.nickname}"}) {
          id
          email
          type
          totalScore
          name
          accuracy
          createdAt
          highestMentalLevel
          rank
          reactionTime
          totalDuration
          updatedAt
        }
      }`)
    );

    // Update the local data so we dont have to call API again.
    for (var i = 0; i < this.props.leaderBoardData.length; i++) {
      if (
        this.userInfo.email === this.props.leaderBoardData[i].email) 
      {
        // Exists
        // Update this row local leaderboard so we dont have to call API again.
        this.props.leaderBoardData[i] = apiData.data.updateLeaderboardData;
        // sort by DESC score.
        this.props.leaderBoardData
          .sort(function (a, b) {
            return a.totalScore - b.totalScore;
          })
          .reverse();
        break;
      }
    }
  };

  checkLeaderBoard(plName, plEmail) {
    // Update the local user data:
    this.userInfo.nickname = plName;
    this.userInfo.email = plEmail;
    let isUniqueNickname = true;
    this.isNicknameTaken = false;

    // Check if this athlete exists in the leaderboard.
    for (var i = 0; i < this.props.leaderBoardData.length; i++) {
      if (
        this.userInfo.email === this.props.leaderBoardData[i].email &&
        this.userInfo.nickname !== this.props.leaderBoardData[i].name
      ) {
        this.updateAthleteNameMutation(this.props.leaderBoardData[i].id);
        break;
      } else if (
        this.userInfo.email !== this.props.leaderBoardData[i].email &&
        this.userInfo.nickname === this.props.leaderBoardData[i].name
      ) {
        this.isNicknameTaken = true;
        this.refNickNameInput.current.select();
        this.refNickNameInput.current.focus();
        isUniqueNickname = false;
        break;
      }
    }
    return isUniqueNickname;
  }

  setACookie(plName, plEmail) {
    let date = new Date();
    date.setTime(date.getTime()+(60*60*24*365));
    document.cookie = "mtcPlrName=" + plName;
    document.cookie = "mtcPlrEmail=" + plEmail + ";expires=" + date.toUTCString();

    // Update the local user data:
    this.userInfo.nickname = plName;
    this.userInfo.email = plEmail;
  }

  handleSubmit(event) {
    const data = new FormData(this.formMtc.current);

    event.preventDefault();

    // --
    if (!this.state.initialSubmit) {
      this.validateForm();
      if (this.state.validNickname && this.state.validEmail) {
        // Check the Leaderboard and update if necessary. #70, #97
        if (this.checkLeaderBoard(this.state.fullname, this.state.email)) {
          this.setACookie(this.state.fullname, this.state.email);

          this.setState({ submitClicked: true });

          fetch("https://rewirefitness.activehosted.com/proc.php", {
            method: "POST",
            body: data,
            mode: "no-cors",
          })
            .then((response) => {
              this.setState({ formSubmitted: true });
              // Skip the Thank You page and go to Instructions/Game.
              this.props.onClickTakeChallengeNow();
            })
            .catch((error) => {
              console.error("Error:", error);
              this.setState({ formError: true });
            });
        }
      }
    }

    //--
    this.setState(
      {
        initialSubmit: true,
      },
      () => {
        this.validateForm();
        if (this.state.validNickname && this.state.validEmail) {
          if (this.checkLeaderBoard(this.state.fullname, this.state.email)) {
            this.setACookie(this.state.fullname, this.state.email);

            this.setState({ submitClicked: true });

            fetch("https://rewirefitness.activehosted.com/proc.php", {
              method: "POST",
              body: data,
              mode: "no-cors",
            })
              .then((response) => {
                this.setState({ formSubmitted: true });
                // Skip the Thank You page and go to Instructions/Game.
                this.props.onClickTakeChallengeNow();
                // if (!response.ok) {
                //   // make the promise be rejected if we didn't get a 2xx response
                //   throw new Error("Not 2xx response")
                // }
                // setTimeout(() => {
                //   this.setState({ formSubmitted: false });
                // }, 5000);
              })
              .catch((error) => {
                console.error("Error:", error);
                this.setState({ formError: true });
              });
          }
        } else {
          if (!this.state.validEmail) {
            this.refEmailInput.current.select();
            this.refEmailInput.current.focus();
          }
          if (!this.state.validNickname) {
            this.refNickNameInput.current.select();
            this.refNickNameInput.current.focus();
          }
        }
      }
    );
  }

  validateForm() {
    if (!this.state.fullname) {
      this.setState({ validNickname: false });
    } else {
      this.setState({ validNickname: true });
    }

    if (!this.state.email) {
      this.setState({ validEmail: false });
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email)) {
        this.setState({ validEmail: false });
      } else {
        this.setState({ validEmail: true });
      }
    }
  }

  render() {
    const { formSubmitted } = this.state.formSubmitted;

    let errorNicknameDescTxt = "* Please enter a Nickname";

    let errorNicknameDisplayStyle = { display: "none" };
    let errorEmailDisplayStyle = { display: "none" };

    if (this.state.initialSubmit) {
      if (this.isNicknameTaken) {
        errorNicknameDescTxt = "* Nickname Taken, Please Try Again.";
        errorNicknameDisplayStyle = { color: "red" };
      }

      if (!this.state.validEmail) {
        errorEmailDisplayStyle = { color: "red" };
      }

      if (!this.state.validNickname) {
        errorNicknameDisplayStyle = { color: "red" };
      }
    }

    let styleDispNone = {display:"none"};

    let leadScript = document.createElement("script");
    leadScript.type = 'text/javascript';
    let leadSCriptCode = `fbq('track', 'Lead', {
    });`;

    if (this.state.formSubmitted && !this.state.formError) {
      try {
        leadScript.appendChild(document.createTextNode(leadSCriptCode));
        document.body.appendChild(leadScript);
      } catch (e) {
        leadScript.text = leadSCriptCode;
        document.body.appendChild(leadScript);
      }
    }
    

    return (
      <div className="info-form-container">
        <img src={logo} className="img-logo-info-form" />

        {this.state.formError && (
          <center>
            <div>
              <center>
                <span className="info-form-h2">
                  Oops... something went wrong. Take the challenge anyway.
                  <br />
                  <br />
                </span>
                <button
                  className="btn-take-challange-now"
                  onClick={this.props.onClickTakeChallengeNow}
                >
                  TAKE MENTAL CHALLENGE NOW
                </button>
                <br />
                <br />
              </center>
            </div>
          </center>
        )}

        {!this.state.formSubmitted && (
          <div className="info-form">
            <span className="info-form-h1">How Mentally Tough Are You?</span>
            <br />
            <span className="info-form-h2">
              Take the Mental Toughness Challenge.
            </span>
            <br />
            <br />
            <form
              ref={this.formMtc}
              method="POST"
              action="https://rewirefitness.activehosted.com/proc.php"
              onSubmit={this.handleSubmit}
              id="_form_1_"
            >
              <input type="hidden" name="u" value="1" />
              <input type="hidden" name="f" value="1" />
              <input type="hidden" name="s" />
              <input type="hidden" name="c" value="0" />
              <input type="hidden" name="m" value="0" />
              <input type="hidden" name="act" value="sub" />
              <input type="hidden" name="v" value="2" />
              <label className="info-form-label">Nickname</label>
              &nbsp;&nbsp;&nbsp;
              <label
                className="info-form-label"
                style={errorNicknameDisplayStyle}
              >
                {" "}
                {errorNicknameDescTxt}
              </label>
              <br />
              <input
                name="fullname"
                className="info-form-text-field"
                type="text"
                value={this.state.fullname}
                onChange={this.handleInputChange}
                ref={this.refNickNameInput}
              />
              <br />
              <label className="info-form-label">Email</label>&nbsp;&nbsp;&nbsp;
              <label className="info-form-label" style={errorEmailDisplayStyle}>
                {" "}
                * Please enter a valid Email
              </label>
              <br />
              <input
                name="email"
                className="info-form-text-field"
                type="text"
                value={this.state.email}
                onChange={this.handleInputChange}
                ref={this.refEmailInput}
              />
               {/*<br />
              <label className="info-form-label">
                Primary interest in Rewire?
              </label>
              <br />
              <select
                name="field[5]"
                className="info-form-select"
                onChange={this.handleInputChange}
              >
                <option value="Just Curious">Just Curious</option>
                <option value="Pro-Athlete">Pro-Athlete</option>
                <option value="Amateur Athlete">Amateur Athlete</option>
              </select> */}
              <br />
              <br />
              <input
                dataautofill="false"
                type="hidden"
                name="field[6][]"
                value="~|"
              ></input>
              <input
                id="field_6Receive free mental toughness training plan"
                value="Receive free mental toughness training plan"
                name="field[6][]"
                defaultChecked="checked"
                className="info-form-checkbox"
                type="checkbox"
                onChange={this.handleInputChange}
              />
              <label className="info-form-label">
                &nbsp;&nbsp;Receive updates on the mental toughness challenge
                and product news
              </label>
              <br />
              <br />
              {this.state.submitClicked && (
                <center>
                  <br />
                  <br />
                  <label className="info-form-label">
                    &nbsp;&nbsp;Submitting...
                  </label>
                </center>
              )}
              <br />
              <br />
              {!this.state.submitClicked && (
                <input
                  type="submit"
                  value="TAKE MENTAL CHALLENGE NOW"
                  className="btn-take-challange-now info-form-submit"
                />
              )}
              <br />
              <br />
            </form>
            <br />
            <br />
          </div>
        )}
      </div>
    );
  }
}

export default InfoForm;
