import React from "react";

function ButtonDoPracticeSession(props) {
    // Concatenate css
    return (
        <button 
            className="btnTapOut" 
            onClick={props.onClick}>
            DO PRACTICE SESSION
        </button>
    );
}

class PracticeFooter extends React.Component {
    constructor(props) {
      super(props);
    } 
    
    render() {

        return (
            <div className="tap-out-footer">
                <div className="practice-footer-correct-answer-txt">
                    Correct Answer!
                </div>
                <ButtonDoPracticeSession onClick={this.props.onClick}/>
            </div>
        );
        
    };
}

export default PracticeFooter;