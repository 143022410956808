export const gameConfigData = {
    questionTimer: 5000, // MiliSeconds 
    betweenIntervalsTimer: 6000, // Miliseconds
    statsTimer: 9000, // Miliseconds
    inactiveTimer: 35000, // Miliseconds
    tapoutTimer: 10000, // Miliseconds
    isDebug: false,
    linkShareFB: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.mentaltoughness.app%2F%3Futm_source%3Dfb%26utm_medium%3Dusershare%26utm_campaign%3Dmental-toughness",
    linkShareTW: "https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.mentaltoughness.app%2F%3Futm_source%3Dtwt%26utm_medium%3Dusershare%26utm_campaign%3Dmental-toughness&text=I just took Rewire's Mental Toughness Challenge! How mentally tough are you?",
    linkShareLI: "https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.mentaltoughness.app%2F%3Futm_source%3Dln%26utm_medium%3Dusershare%26utm_campaign%3Dmental-toughness&title=&summary=&source=",
    linkShareEmail: "mailto:?subject=How%20mentally%20tough%20are%20you%3F&body=I've%20just%20taken%20Rewire's%20mental%20toughness%20challenge!%0A%0AFind%20out%20how%20mentally%20tough%20you%20are%20here%3A%20https%3A%2F%2Fwww.mentaltoughness.app%2F%3Futm_source%3Dem%26utm_medium%3Dusershare%26utm_campaign%3Dmental-toughness",
    linkLearnMore: "https://rewirefitness.app/get-rewire-mental-toughness-app",
    linkGameOverLearnMore: "https://rewirefitness.app/get-rewire-mental-toughness-app-final-pg-button1",
    linkMasterClass: "https://rewirefitness.app/get-rewire-mental-toughness-app",
    linkLearnAboutTheScience: "https://rewirefitness.app/science-mental-toughness-app",
    linkResults: "http://rewire.vip/?utm_source=ChallengeMAINPage",
    linkPrivacyPolicy: "https://rewirefitness.app/privacy-policy/",
    linkContestTerms: "https://rewirefitness.app/contests/mental-toughness-challenge-contest-terms/",
    mentalToughnessRank: {
        1: ["NEWBIE","You are not on top of your mental game and are in need of some serious training."],
        2: ["ROOKIE","You are not on top of your mental game and are in need of some training."],
        3: ["SKILLED","Your mental game is coming together but there is a lot of room for improvement."],
        4: ["TALENTED","You have a decent ability to maintain focus whilst having good mental endurance."],
        5: ["ADVANCED","You have a good ability to maintain focus whilst having strong mental endurance."],
        6: ["EXPERT","You have a very good ability to maintain focus whilst having impressive mental endurance."],
        7: ["CHAMPION","You have a great ability to maintain focus whilst having fantastic mental endurance."],
        8: ["SEMI-PRO","You have an excellent ability to maintain focus whilst having exceptional mental endurance."],
        9: ["PRO","You’re on top of your mental game with an awesome ability to maintain focus whilst having outstanding mental endurance."],
        10: ["ELITE","You’re on top of your mental game with a unique ability to maintain focus whilst having phenomenal mental endurance."]
    }
};
    