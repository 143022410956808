import React from "react";

function ButtonTapOut(props) {
    // Concatenate css
    return (
        <button 
            className="btnTapOut" 
            onClick={props.onClick}>
            TAP OUT NOW (ENDS GAME)
        </button>
    );
}

class TapOutFooter extends React.Component {
    constructor(props) {
      super(props);
      //this.containerStyles = {color: "#000"};
      this.containerStyles = {};
      this.cssStyleBlack = {color: "#000"};
    } 
    
    render() {
        let content;

        if (this.props.isTapOutWarningVisible) {
          //this.containerStyles = {};
          content = (
            <div className="tap-out-footer">
              <div className="auto-tap-warning">
                <span
                  className="auto-tap-warning-red"
                  style={this.containerStyles}
                >
                  WARNING: AUTO TAP OUT IN:{" "}
                </span>
                <span
                  className="auto-tap-warning-white"
                  style={this.containerStyles}
                >
                  {this.props.tapoutTime} SECONDS
                </span>
              </div>
              <ButtonTapOut onClick={this.props.onClick} />
            </div>
          );
        } else if (this.props.isFaded) {
          //this.containerStyles = {color: "#000"};
          content = (
            <div className="tap-out-footer">
              <div className="auto-tap-warning">
                <span
                  className="auto-tap-warning-red"
                  style={this.containerStyles}
                >
                  WARNING:{" "}
                  <span className="auto-tap-warning-white">FADED COLORS</span>{" "}
                </span>
                <span
                  className="auto-tap-warning-white"
                  style={this.containerStyles}
                ></span>
              </div>
              <ButtonTapOut onClick={this.props.onClick} />
            </div>
          );
        } else if (this.props.isPracticeRound) {
          content = (
            <div className="tap-out-footer">
              <div className="practice-round-footer-instructions">
                Tap on the buttons on your smartphone or from a desktop computer
                use "Z" key for left answer and the "X" key for the right
                answer.
              </div>
            </div>
          );
        } else if (this.props.isAugWords) {
            //this.containerStyles = {color: "#000"};
            content = (
              <div className="tap-out-footer">
                <div className="auto-tap-warning">
                  <span
                    className="auto-tap-warning-red"
                    style={this.containerStyles}
                  >
                    WARNING:{" "}
                    <span className="auto-tap-warning-white">
                      AUGMENTED WORDS
                    </span>{" "}
                  </span>
                  <span
                    className="auto-tap-warning-white"
                    style={this.containerStyles}
                  ></span>
                </div>
                <ButtonTapOut onClick={this.props.onClick} />
              </div>
            );
        } else if (this.props.isReverseIn) {
          //this.containerStyles = {color: "#000"};
          content = (
            <div className="tap-out-footer">
              <div className="auto-tap-warning">
                <span
                  className="auto-tap-warning-red"
                  style={this.containerStyles}
                >
                  <span className="auto-tap-warning-white">---&gt;</span>
                  &nbsp;&nbsp;WARNING:{" "}
                  <span className="auto-tap-warning-white">
                    REVERSED BUTTONS&nbsp;&nbsp;
                    <span className="auto-tap-warning-red">&lt;---</span>{" "}
                  </span>
                </span>
                <span
                  className="auto-tap-warning-white"
                  style={this.containerStyles}
                ></span>
              </div>
              <ButtonTapOut onClick={this.props.onClick} />
            </div>
          );
        } else {
            content = (
              <div className="tap-out-footer">
                <div className="auto-tap-warning">
                  <span
                    className="auto-tap-warning-red"
                    style={this.cssStyleBlack}
                  >
                    N/A{" "}
                  </span>
                </div>
                <ButtonTapOut onClick={this.props.onClick} />
              </div>
            );
        }

        return (
            content
        );
        
    };
}

export default TapOutFooter;